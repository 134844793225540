import signin from "../signin"
import formatters from "utils/formatters"
import { Simulations } from "./entities"

const check = ({ document }) => {
  return signin()
    .then(({ http }) => {
      return http.post("/itau-redsales-api/simulations/", {
        document: formatters.removeSpecialChars(document),
      })
    })
    .then(Simulations)
}

export default {
  check,
}
