export const getProducts = ({ group_id }) => ({
  12: {
    name: "Conta Corrente",
    path: ROUTES.checkingAccount.path,
    icon: "shop",
    permission: {
      subject: "management",
      action: "checkingAccount",
    },
    children: [
      {
        name: "Clientes Potenciais",
        path: ROUTES.checkingAccountPotentialCustomersCompanies.path,
        permission: {
          subject: ["master"],
          action: ["it"],
        },
      },
      {
        name: "Clientes Potenciais",
        path: `${ROUTES.checkingAccountPotentialCustomersCompany.path}?group_id=${group_id}`,
        permission: {
          subject: ["checkingAccount", "admin"],
          action: ["admin-management", "it"],
          and: true,
        },
      },
      {
        name: "Clientes Potenciais",
        path: ROUTES.checkingAccountPotentialCustomers.path,
        permission: {
          subject: ["checkingAccount", "salesman"],
          action: ["leads-management", "it"],
          and: true,
        },
      },
      {
        name: "Upload",
        path: `${ROUTES.checkingAccountUpload.path}`,
        permission: {
          subject: "checkingAccount",
          action: "upload",
        },
      },
    ],
  },
  7: {
    name: "Crédito Consignado",
    path: ROUTES.consignment.path,
    icon: "negotiation",
    permission: {
      subject: "management",
      action: "consignment",
    },
    children: [
      {
        name: "Clientes Potenciais",
        path: ROUTES.consignmentPotentialCustomersCompanies.path,
        permission: {
          subject: ["master"],
          action: ["it"],
        },
      },
      {
        name: "Clientes Potenciais",
        path: `${ROUTES.consignmentPotentialCustomersCompany.path}?group_id=${group_id}`,
        permission: {
          subject: ["consignment", "admin"],
          action: ["admin-management", "it"],
          and: true,
        },
      },
      {
        name: "Clientes Potenciais",
        path: ROUTES.consignmentPotentialCustomers.path,
        permission: {
          subject: ["consignment", "salesman"],
          action: ["leads-management", "it"],
          and: true,
        },
      },
      {
        name: "Upload",
        path: `${ROUTES.consignmentUpload.path}`,
        permission: {
          subject: "consignment",
          action: "upload",
        },
      },
    ],
  },
  9: {
    name: "FGTS",
    path: ROUTES.fgts.path,
    icon: "piggy-bank",
    permission: {
      subject: "management",
      action: "fgts",
    },
    children: [
      {
        name: "Clientes Potenciais",
        path: ROUTES.fgtsPotentialCustomersCompanies.path,
        permission: {
          subject: ["master"],
          action: ["it"],
        },
      },
      {
        name: "Clientes Potenciais",
        path: `${ROUTES.fgtsPotentialCustomersCompany.path}?group_id=${group_id}`,
        permission: {
          subject: ["fgts", "admin"],
          action: ["admin-management", "it"],
          and: true,
        },
      },
      {
        name: "Clientes Potenciais",
        path: ROUTES.fgtsPotentialCustomers.path,
        permission: {
          subject: ["fgts", "salesman"],
          action: ["leads-management", "it"],
          and: true,
        },
      },
      {
        name: "Upload",
        path: `${ROUTES.fgtsUpload.path}`,
        permission: {
          subject: "fgts",
          action: "upload",
        },
      },
    ],
  },
  2: {
    name: "Microcrédito",
    path: ROUTES.microloans.path,
    icon: "income",
    permission: {
      subject: "management",
      action: "itiMicroloans",
    },
    children: [
      {
        name: "Clientes Potenciais",
        path: ROUTES.microloansPotentialCustomersCompanies.path,
        permission: {
          subject: ["master"],
          action: ["it"],
        },
      },
      {
        name: "Clientes Potenciais",
        path: `${ROUTES.microloansPotentialCustomersCompany.path}?group_id=${group_id}`,
        permission: {
          subject: ["itiMicroloans", "admin"],
          action: ["admin-management", "it"],
          and: true,
        },
      },
      {
        name: "Clientes Potenciais",
        path: ROUTES.microloansPotentialCustomers.path,
        permission: {
          subject: ["itiMicroloans", "salesman"],
          action: ["leads-management", "it"],
          and: true,
        },
      },
      {
        name: "Carteiras",
        path: `${ROUTES.microloansWalletActives.path}`,
        permission: {
          subject: "itiMicroloans",
          action: "wallet-management",
        },
      },
      {
        name: "Upload",
        path: `${ROUTES.microloansUpload.path}`,
        permission: {
          subject: "itiMicroloans",
          action: "upload",
        },
      },
      {
        name: "Propostas",
        path: ROUTES.microloansProposals.path,
        permission: {
          subject: "itiMicroloans",
          action: "proposals",
        },
      },
      {
        name: "Remuneração",
        path: ROUTES.microloansRemuneration.path,
        permission: {
          subject: "itiMicroloans",
          action: "remuneration",
        },
      },
    ],
  },
  4: {
    name: "Rede",
    path: ROUTES.rede.path,
    icon: "machine",
    permission: {
      subject: "management",
      action: "rede",
    },
    children: [
      {
        name: "Clientes Potenciais",
        path: ROUTES.redePotentialCustomersCompanies.path,
        permission: {
          subject: ["master"],
          action: ["it"],
        },
      },
      {
        name: "Clientes Potenciais",
        path: `${ROUTES.redePotentialCustomersCompany.path}?group_id=${group_id}`,
        permission: {
          subject: ["rede", "admin"],
          action: ["admin-management", "it"],
          and: true,
        },
      },
      {
        name: "Clientes Potenciais",
        path: ROUTES.redePotentialCustomers.path,
        permission: {
          subject: ["rede", "salesman"],
          action: ["leads-management", "it"],
          and: true,
        },
      },
      {
        name: "Upload",
        path: `${ROUTES.redeUpload.path}`,
        permission: {
          subject: "rede",
          action: "upload",
        },
      },
    ],
  },
  13: {
    name: "iCDC",
    path: ROUTES.icdc.path,
    icon: "phone-apps",
    permission: {
      subject: "management",
      action: "icdc",
    },
    children: [
      {
        name: "Propostas",
        path: ROUTES.icdcProposals.path,
        permission: {
          subject: "icdc",
          action: "proposals",
        },
      },
    ],
  },
  15: {
    name: "Consórcio",
    path: ROUTES.consortium.path,
    icon: "consortium",
    permission: {
      subject: "management",
      action: "consortium",
    },
    children: [
      {
        name: "Clientes Potenciais",
        path: ROUTES.consortiumPotentialCustomersCompanies.path,
        permission: {
          subject: ["master"],
          action: ["it"],
        },
      },
      {
        name: "Clientes Potenciais",
        path: `${ROUTES.consortiumPotentialCustomersCompany.path}?group_id=${group_id}`,
        permission: {
          subject: ["consortium", "admin"],
          action: ["admin-management", "it"],
          and: true,
        },
      },
      {
        name: "Clientes Potenciais",
        path: ROUTES.consortiumPotentialCustomers.path,
        permission: {
          subject: ["consortium", "salesman"],
          action: ["leads-management", "it"],
          and: true,
        },
      },
    ],
  },
  16: {
    name: "Veículos",
    path: ROUTES.vehicles.path,
    icon: "vehicles",
    permission: {
      subject: "management",
      action: "vehicles",
    },

    children: [
      {
        name: "Clientes Potenciais",
        path: ROUTES.vehiclesPotentialCustomersCompanies.path,
        permission: {
          subject: ["master"],
          action: ["it"],
        },
      },
      {
        name: "Clientes Potenciais",
        path: `${ROUTES.vehiclesPotentialCustomersCompany.path}?group_id=${group_id}`,
        permission: {
          subject: ["vehicles", "admin"],
          action: ["admin-management", "it"],
          and: true,
        },
      },
      {
        name: "Clientes Potenciais",
        path: ROUTES.vehiclesPotentialCustomers.path,
        permission: {
          subject: ["vehicles", "salesman"],
          action: ["leads-management", "it"],
          and: true,
        },
      },
    ],
  },
})
