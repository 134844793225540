import { API } from "gatsby-env-variables"
import authResolve from "./authResolve"
import loader from "utils/loader"
import message from "utils/message"
import isClient from "utils/isClient"

const handlersHttpRequests = (hideLoader) => (config) => {
  const { http } = config

  const onRequest = (response) => {
    if (!hideLoader) {
      loader.show()
    }
    return response
  }

  const onSuccess = (response) => {
    loader.hide()
    return response
  }

  const onFailure = (http) => {
    const response = http?.response
    const data = response?.data

    if (response) {
      if (data?.error) {
        loader.hide()
      }

      if (data?.errors && data?.code === "error_list") {
        message({ errors: data?.errors })
      }

      if (!data?.code && response.status === 401) {
        if (isClient()) {
          return (window.location.href = ROUTES.login.path)
        }
      }

      if (!data?.code) {
        message({ notification: data?.message })
      }

      return Promise.resolve(response)
    }

    message({ notification: "Encontramos um erro desconhecido" })

    return Promise.reject(http)
  }

  http.interceptors.request.use(onRequest)
  http.interceptors.response.use(onSuccess, onFailure)

  return config
}

const signin = (config) => {
  return authResolve({
    key: config?.key || "appToken",
    url: `${API.ROOT}/authorization/`,
    renewUrl: `${API.ROOT}/authorization/new/`,
    baseURL: API.ROOT,
    headers: {
      ...API.HEADERS,
      contentType: config?.contentType || "application/json",
    },
  }).then(handlersHttpRequests(config?.hideLoader))
}

export default signin
