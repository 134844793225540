/* eslint-disable react-hooks/rules-of-hooks */
import isClient from "utils/isClient"
import { useQueryParam } from "use-query-params"

export const useParameter = (parameter) => {
  if (isClient()) {
    const [param, setParam] = useQueryParam(parameter)

    return [param, setParam]
  }

  return [null, null]
}

export const useParameters = (...args) => {
  const params = args.flat()

  return params.reduce((list, name) => {
    return {
      ...list,
      [name]: useParameter(name),
    }
  }, {})
}
