const REMOVAL_TIME = 10000

const message = ({ errors, remove, notification }) => {
  const DOMMessage = document.querySelector("[data-message]")
  const DOMMessageContent = document.querySelector("[data-message-content]")
  const DOMMessageClose = document.querySelector("[data-message-close]")

  DOMMessage.classList.add("message-feedback--active")
  DOMMessageClose.addEventListener("click", () =>
    DOMMessage.classList.remove("message-feedback--active")
  )
  setTimeout(
    () => DOMMessage.classList.remove("message-feedback--active"),
    REMOVAL_TIME
  )

  if (errors) {
    DOMMessage.classList.add("message-feedback--errors")
    const listErrors = `
			<ul> 
				${errors.map(({ message }) => `<li>${message}</li>`)}
			</ul>
		`
    return (DOMMessageContent.innerHTML = listErrors)
  }

  if (notification) {
    const text = `<p>${notification}</p>`
    DOMMessageContent.innerHTML = text
  }

  if (remove) {
    DOMMessage.classList.remove("message-feedback--auth")
    DOMMessage.classList.remove("message-feedback--active")

    DOMMessageContent.innerHTML = ""
  }
}

export default message
