import {
  calc,
  getCurrentLeadByStatusId,
  getCurrentValue,
  formatToWrittenDate,
} from "./utils"
import date from "utils/date"
import string from "utils/string"
import formatters from "utils/formatters"

/** @type {Proposals} */
export const Proposals = ({ data = [] }) => {
  return {
    ...data,
    last_updated_at: formatToWrittenDate(data.last_updated_at),
    paginated_result: {
      ...data.paginated_result,
      data: data?.paginated_result?.data?.map((item) => ({
        ...item,
        document_number: formatters.toDocument(item?.document_number),
        phone_number: formatters.toPhone(item?.phone_number),
      })),
    },
  }
}

/** @type {Iframes} */
export const Iframes = ({ data = [] }) =>
  data?.iframe && Array.isArray(data?.iframe)
    ? data?.iframe?.map((item) => ({
        title: item?.name,
        src: item?.iframe_url,
      }))
    : []

/** @type {Leads} */
export const Leads = ({ data = [] }) => ({
  ...data,
  kanban: data?.kanban?.map(Kanban),
})

/** @type {Kanban} */
export const Kanban = (item = Object()) => ({
  ...item,
  paginated_result: {
    ...item.paginated_result,
    data: item.paginated_result.data.map(LeadPaginationResult),
  },
})

/** @type {LeadPaginationResult} */
export const LeadPaginationResult = (item = Object()) => ({
  ...item,
  phone_number:
    item.phone_number &&
    formatters.toPhone(formatters.removeSpecialChars(item.phone_number)),
})

/** @type {Lead} */
export const Lead = ({ data = Object() }) => ({
  ...data,
  lead: {
    ...data.lead,
    employer_document_number: data?.lead?.employer_document_number
      ? formatters.toCnpj(data?.lead?.employer_document_number)
      : "",
    pre_approved_value: data?.lead?.pre_approved_value
      ? formatters.toCurrency(data?.lead?.pre_approved_value)
      : "",
    document_number: data?.lead?.document_number
      ? formatters.toDocument(data?.lead?.document_number)
      : "",
    approved_value: data?.lead?.approved_value
      ? formatters.toCurrency(data?.lead?.approved_value)
      : "",
    available_value: data?.lead?.available_value
      ? formatters.toCurrency(data?.lead?.available_value)
      : "",
    installments_value: data?.lead?.installments_value
      ? formatters.toCurrency(data?.lead?.installments_value)
      : "",
    asset_value: data?.lead?.asset_value
      ? formatters.toCurrency(data?.lead?.asset_value)
      : "",
    credit_value: data?.lead?.credit_value
      ? formatters.toCurrency(data?.lead?.credit_value)
      : "",
    reserve_fund: formatters.toPercent({
      value: data?.lead?.reserve_fund,
      nullableReturn: true,
    }),
    admin_fee: formatters.toPercent({
      value: data?.lead?.admin_fee,
      nullableReturn: true,
    }),
    commercial_address_postal_code: data?.lead?.commercial_address_postal_code
      ? formatters.toPostalCode(
          formatters.removeSpecialChars(
            data?.lead?.commercial_address_postal_code
          )
        )
      : "",
    address_postal_code: data?.lead?.address_postal_code
      ? formatters.toPostalCode(
          formatters.removeSpecialChars(data?.lead?.address_postal_code)
        )
      : "",
    new_offers: OffersValues(data?.lead?.new_offers),
    phone_numbers: PhonesNumbers(data?.lead?.phone_numbers),
    offer_rate: formatters.toPercent({
      value: data?.lead?.offer_rate,
      nullableReturn: true,
    }),
    payroll_offers: OffersValues(data?.lead?.payroll_offers),
    income_value: formatters.toCurrency(data?.lead?.income_value),
    postal_code: data?.lead?.postal_code
      ? formatters.toPostalCode(data?.lead?.postal_code)
      : "",
    zip_code: data?.lead?.zip_code
      ? formatters.toPostalCode(data?.lead?.zip_code)
      : "",
    revenues_range: formatters.toCurrency(data?.lead?.revenues_range),
    requested_value: formatters.toCurrency(data?.lead?.requested_value),
    installment_value: formatters.toCurrency(data?.lead?.installment_value),
    desired_loan_value: formatters.toCurrency(data?.lead?.desired_loan_value),
    refinanced_balance: formatters.toCurrency(data?.lead?.refinanced_balance),
    asset_initial_value: formatters.toCurrency(data?.lead?.asset_initial_value),
    installment_initial_value: formatters.toCurrency(
      data?.lead?.installment_initial_value
    ),
    is_bypass: formatters.toPortugueseBoolean(data?.lead?.is_bypass),
    has_credit: formatters.toPortugueseBoolean(data?.lead?.has_credit),
    origin_contract_rate: formatters.toPercent({
      value: data?.lead?.origin_contract_rate,
      nullableReturn: true,
    }),
    rate_monthly: formatters.toPercent({
      value: data?.lead?.rate_monthly,
      nullableReturn: true,
    }),
    max_annual_tec: formatters.toPercent({
      value: data?.lead?.max_annual_tec,
      nullableReturn: true,
    }),
    origin_in_arrear_value: formatters.toCurrency(
      data?.lead?.origin_in_arrear_value
    ),
    origin_installment_value: formatters.toCurrency(
      data?.lead?.origin_installment_value
    ),
    anticipation_data: !!data?.lead?.anticipation_data
      ? [data?.lead?.anticipation_data]
          .flat()
          .map((anticipation) =>
            anticipation?.anticipation_value || anticipation?.due_date
              ? string.concatenate(
                  anticipation?.due_date || "ND",
                  formatters.toCurrency(anticipation?.anticipation_value) ||
                    "ND",
                  " - "
                )
              : null
          )
      : null,
    city_and_fu:
      data?.lead?.city && data?.lead?.federative_unit
        ? string.concatenate(data?.lead?.city, data?.lead?.federative_unit)
        : null,
    released_value: formatters.toCurrency(data?.lead?.released_value),
    installment_value_inss: formatters.toCurrency(
      data?.lead?.installment_value_inss
    ),
    effective_monthly_rate: formatters.toPercent({
      value: data?.lead?.effective_monthly_rate,
      nullableReturn: true,
    }),
    financial_operation_tax: formatters.toPercent({
      value: data?.lead?.financial_operation_tax,
      nullableReturn: true,
    }),
    total_financed_value: formatters.toCurrency(
      data?.lead?.total_financed_value
    ),
    debt_groups:
      data?.lead?.debt_groups?.map((debt) => ({
        ...debt,
        original_debt_value: formatters.toCurrency(
          Number(debt?.original_debt_value)
        ),
        min_current_debt_value: formatters.toCurrency(
          Number(debt?.min_current_debt_value)
        ),
      })) || [],
    entry_value: data?.lead?.entry_value
      ? formatters.toCurrency(data?.lead?.entry_value)
      : "",
    vehicle_price: data?.lead?.vehicle_price
      ? formatters.toCurrency(data?.lead?.vehicle_price)
      : "",
  },
})

/** @type {PhonesNumbers} */
export const PhonesNumbers = (phone_numbers = []) =>
  phone_numbers
    .filter(Boolean)
    .map((item) => formatters.toPhone(formatters.removeSpecialChars(item)))

/** @type {OffersValues} */
export const OffersValues = (offers = null) =>
  offers?.filter((offer) => !!offer?.value).map((item) => item.value)

/** @type {Redistribution} */
export const Redistribution = ({
  navegation_business = [],
  users_table = Object(),
  user_name = String(),
  user_uuid = String(),
}) => ({
  user_uuid,
  user_name,
  navegation_business: NavigationBusiness({ navegation_business }),
  navegation_business_by_ids: NavigationBusinessByIds({ navegation_business }),
  users_table: UsersTable({
    navegation_business: NavigationBusiness({ navegation_business }),
    users_table,
  }),
})

/** @type {UsersTable} */
export const UsersTable = ({
  navegation_business = [],
  users_table = Object(),
}) =>
  Object.keys(users_table).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        header: Header({ users_table, navegation_business, key }),
        users: Users({ users_table, navegation_business, key }),
      },
    }),
    {}
  )

/** @type {NavigationBusinessByIds} */
export const NavigationBusinessByIds = ({ navegation_business = [] }) =>
  navegation_business.reduce(
    (acc, curr) => ({ ...acc, [curr.business_id]: { ...curr } }),
    {}
  )

/** @type {NavigationBusiness} */
export const NavigationBusiness = ({ navegation_business = [] }) =>
  navegation_business.map((item) => ({
    ...item,
    leads_count_by_status: item.leads_count_by_status?.map(LeadNavigation),
  }))

/** @type {LeadNavigation} */
export const LeadNavigation = (lead = Object()) => ({
  ...lead,
  disabled: !lead.count ? true : false,
  active: lead.count ? true : false,
})

/** @type {Header} */
export const Header = ({
  users_table = Object(),
  navegation_business = [],
  key = Number,
}) =>
  users_table[key].header.map((item) => {
    const currentLead = getCurrentLeadByStatusId({
      navegation_business,
      key,
      object: item,
    })
    return {
      ...item,
      active: !currentLead?.disabled,
    }
  })

/** @type {Users} */
export const Users = ({
  users_table = Object(),
  navegation_business = [],
  key = Number(),
}) => {
  if (users_table[key]?.users) {
    return users_table[key].users.map((user, index) =>
      User({ users_table, user, index, navegation_business, key })
    )
  }
  return []
}

/** @type {User} */
export const User = ({
  user = Object(),
  index = Number(),
  navegation_business = [],
  key = Number(),
  users_table = [],
}) => ({
  ...user,
  active: true,
  leads_count: user.leads_count.map((lead) =>
    UserLead({ users_table, navegation_business, key, lead, index })
  ),
})

/** @type {UserLead} */
export const UserLead = ({
  lead = Object(),
  index = Number(),
  key = Number(),
  navegation_business = [],
  users_table = [],
}) => {
  const currentLead = getCurrentLeadByStatusId({
    navegation_business,
    key,
    object: lead,
  })

  return {
    ...lead,
    active: !currentLead.disabled,
    quantity: calc({
      quantity: currentLead.count,
      users: users_table[key].users.length,
      userIndex: index,
    }),
  }
}

/** @type {ManagementCompanies} */
export const ManagementCompanies = ({
  header = [],
  paginated_result = Object(),
}) => ({
  header: header.map((item) => item.title),
  paginated_result: {
    ...paginated_result,
    data: paginated_result?.data?.map((item) => ({
      ...item,
      leads_count: item.leads_count?.map((item) => ({
        ...item,
        count: String(item?.count),
      })),
    })),
  },
})

/** @type {ManagementCompany} */
export const ManagementCompany = ({
  header = [],
  group_name = String(),
  paginated_result = Object(),
}) => ({
  header: header.map((item) => item.title),
  paginated_result: {
    ...paginated_result,
    data: paginated_result?.data?.map((item) => ({
      ...item,
      leads_count: item?.leads_count?.map((item) => ({
        ...item,
        count: String(item?.count),
      })),
    })),
  },
  group_name,
})

/** @type {FormatFilterLeads} */
export const FormatFilterLeads = (filters = Object()) => {
  const format = Object.entries(filters).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      return { ...acc, [key]: value.toString() }
    }

    if (value instanceof Date) {
      return {
        ...acc,
        [key]: formatters.formatStringDate(date.toDateString(value)),
      }
    }

    return { ...acc, [key]: getCurrentValue(value) }
  }, {})

  Object.keys(format).forEach((key) => {
    if (format[key] === "") {
      delete format[key]
    }
  })

  return format
}
