/** @type {Overview} */
export const Overview = ({ data = {} }) => {
  const isNull = (value) => (value === null ? "-" : value)

  const highlights = Object.entries({
    total_leads: data.total_leads,
    received_leads: data.received_leads,
    actuated_leads: data.actuated_leads,
    un_acted_leads: data.un_acted_leads,
    average_acting: data.average_acting,
  }).map(([key, value]) => [key, isNull(value)])

  const period_id = data.period_id

  return {
    highlights,
    period_id,
  }
}

/** @type {Tabs} */
export const Tabs = ({ business_permissions = {} }) => {
  const filterByLeadManagement = Object.values(business_permissions).filter(
    (business) =>
      business.permissions.find(
        (permission) =>
          permission.code === "P000011" || permission.code === "P000007"
      )
  )

  const formatterTabs = filterByLeadManagement.map((item) => ({
    id: item.business_id,
    name: item.business_name,
  }))

  return formatterTabs
}
