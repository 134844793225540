/** @type {CompanyList} */
export const CompanyList = ({ data = [] }) =>
  !data.error
    ? data.map((company) => ({
        id: company?.competitor_company_id,
        name: company?.competitor_company_name,
      })) || []
    : []

/** @type {Fee} */
export const Fee = ({ data = {} }) => ({
  ...data,
  competitor_company: {
    id: !!data?.custom_company_name
      ? 0
      : data?.competitor_company?.competitor_company_id,
    name: data?.competitor_company?.competitor_company_name,
  },
})

/** @type {FeeList} */
export const FeeList = ({ data = [] }) =>
  !data.error && Array.isArray(data)
    ? data?.map((fee) => ({
        ...fee,
        competitor_company: {
          id: !!fee?.custom_company_name
            ? 0
            : fee?.competitor_company?.competitor_company_id,
          name: fee?.competitor_company?.competitor_company_name,
        },
      })) || []
    : []
