import If from "components/If"
import Loader from "components/Loader"
import Message from "components/Message"
import LoggedOut from "./LoggedOut"
import LoggedIn from "./LoggedIn"

import isClient from "utils/isClient"
import SEO from "components/SEO"

const Layout = ({ pageContext, children }) => {
  return (
    <If condition={isClient()}>
      <SEO title={pageContext?.crumbLabel} />
      <Loader />
      <Message />
      <If
        condition={pageContext?.layout === "logged-out"}
        renderIf={<LoggedOut>{children}</LoggedOut>}
        renderElse={<LoggedIn>{children}</LoggedIn>}
      />
    </If>
  )
}

export default Layout
