import "styles/index.styl"
import storage from "utils/storage"
import isClient from "utils/isClient"
import datadog from "services/datadog"

datadog.init()

if (isClient()) {
  window.addEventListener("beforeunload", function (event) {
    storage.session.removeItem("__lock")
  })
  if (storage.session.getItem("__lock")) {
    storage.session.clear()
    console.warn("Found a lock in session storage. The storage was cleared.")
  }
  storage.session.setItem("__lock", "1")
}

export const wrapPageElement = ({ element }) => {
  return <div className="app"> {element}</div>
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // Does not update the scroll position when setting query parameters
  if (location.search) {
    return false
  }

  const currentPosition = getSavedScrollPosition(location)
  return currentPosition || [0, 0]
}
