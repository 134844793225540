import Desktop from "./Desktop"
import Mobile from "./Mobile"

const Navigation = ({ className }) => {
  return (
    <>
      <Desktop className={className} />
      <Mobile />
    </>
  )
}

export default Navigation
