import moment from "moment"
import localization from "moment/locale/pt-br"

moment.updateLocale("pt-br", localization)

const toDateTimeString = (date) => {
  const text = moment(date).format("YYYY-MM-DDTHH:mm")

  return text
}

const toDateString = (date) => moment(date).format("YYYY-MM-DD")

const toBrazilDateString = (date) => moment(date).format("DD/MM/YYYY")

const toDate = (date) => moment(date).toDate()

const getWeek = (i) => {
  return moment(moment().clone().startOf("week")).add(i, "days").format("DD")
}

const getLastWeek = (i) => {
  return moment(moment().clone().subtract(1, "weeks").startOf("week"))
    .add(i, "days")
    .format("DD")
}

const getNextWeek = (i) => {
  return moment().clone().endOf("isoWeek").add(i, "days").format("DD")
}

const getWeekday = (i) => {
  return moment(i, "e").startOf("week").isoWeekday(i).format("ddd")
}

const getNextBRT = (i) => {
  const dates = moment()
    .clone()
    .endOf("isoWeek")
    .add(i, "days")
    .format("DD-MM-YYYY")
  return dates
}

const getCurrentBRT = (i) => {
  const dates = moment(moment().clone().startOf("week"))
    .add(i, "days")
    .format("DD-MM-YYYY")
  return dates
}

const getLastBRT = (i) => {
  const dates = moment(moment().clone().subtract(1, "weeks").startOf("week"))
    .add(i, "days")
    .format("DD-MM-YYYY")
  return dates
}

const getDate = () => {
  return moment(new Date()).format("DD-MM-YYYY")
}

const getMonth = () => {
  return moment(new Date(), "M").format("MMMM")
}

export default {
  getWeek,
  getLastWeek,
  getNextWeek,
  getWeekday,
  getNextBRT,
  getCurrentBRT,
  getLastBRT,
  getDate,
  getMonth,
  toDate,
  toDateString,
  toDateTimeString,
  toBrazilDateString,
}
