import { Fragment } from "react"
import Header from "components/Header"
import Navigation from "components/Navigation"
import If from "components/If"
import Redirect from "components/Redirect"
import Breadcrumb from "components/Breadcrumb"
import FeatureToggle from "components/FeatureToggle"

import useLoggedIn from "./hooks"

import "./LoggedIn.styl"

const LoggedIn = ({ children }) => {
  const { location, pageContext } = children.props
  const { isLoggedIn } = useLoggedIn()

  return (
    <If
      condition={isLoggedIn}
      renderIf={
        <Fragment>
          <Header className="app__header" />
          <Navigation className="app__navigation" />
          <main className="app__main">
            <FeatureToggle>
              <Breadcrumb
                crumbLabel={pageContext.crumbLabel}
                location={{
                  ...location,
                  pathname: location.pathname + location.search,
                }}
              />
            </FeatureToggle>
            {children}
          </main>
        </Fragment>
      }
      renderElse={<Redirect to={ROUTES.login.path} />}
    />
  )
}

export default LoggedIn
