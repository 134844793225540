export const BUSINESS_ID_NAME = {
  2: "itiMicroloans",
  4: "rede",
  7: "consignment",
  9: "fgts",
  12: "checkingAccount",
  13: "icdc",
  14: "itiAcquisition",
  15: "consortium",
  16: "vehicles",
}
export const BUSINESS_ID_NAME_LIST = Object.values(BUSINESS_ID_NAME)

export const BUSINESS_NAME = {
  2: "Microcrédito",
  4: "Rede",
  7: "Crédito Consignado",
  9: "FGTS",
  12: "Conta Corrente Itaú",
  13: "iCDC",
  14: "Adquirência iti",
  15: "Consórcio",
  16: "Veículos",
}

export const BUSINESS = {
  itiMicroloans: {
    id: 2,
    name: BUSINESS_NAME[2],
    nameId: BUSINESS_ID_NAME[2],
    nameApi: String(),
  },
  rede: {
    id: 4,
    name: BUSINESS_NAME[4],
    nameId: BUSINESS_ID_NAME[4],
    nameApi: "rede",
  },
  loansConsigned: {
    id: 7,
    name: BUSINESS_NAME[7],
    nameId: BUSINESS_ID_NAME[7],
    nameApi: String(),
  },
  fgts: {
    id: 9,
    name: BUSINESS_NAME[9],
    nameId: BUSINESS_ID_NAME[9],
    nameApi: String(),
  },
  checkingAccount: {
    id: 12,
    name: BUSINESS_NAME[12],
    nameId: BUSINESS_ID_NAME[12],
    nameApi: String(),
  },
  icdc: {
    id: 13,
    name: BUSINESS_NAME[13],
    nameId: BUSINESS_ID_NAME[13],
    nameApi: String(),
  },
  itiAcquisition: {
    id: 14,
    name: BUSINESS_NAME[14],
    nameId: BUSINESS_ID_NAME[14],
    nameApi: String(),
  },
  consortium: {
    id: 15,
    name: BUSINESS_NAME[15],
    nameId: BUSINESS_ID_NAME[15],
    nameApi: String(),
  },
  vehicles: {
    id: 16,
    name: BUSINESS_NAME[16],
    nameId: BUSINESS_ID_NAME[16],
    nameApi: String(),
  },
}
