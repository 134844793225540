import { ENV } from "gatsby-env-variables"

import { datadogRum } from "@datadog/browser-rum"

const init = () => {
  // TO DO: avaliar se é necessário ter DD aqui. se for atualizar com as novas credenciais
  // datadogRum.init({
  //   applicationId: "46ce84f5-fe7d-42e0-bcde-c0fba0eb392d",
  //   clientToken: "pub165ba3d6e43633b0a003761925191e9a",
  //   // `site` refers to the Datadog site parameter of your organization
  //   // see https://docs.datadoghq.com/getting_started/site/
  //   site: "datadoghq.com",
  //   service: "itau-jn6-app-pivo-react-admin-www",
  //   env: `${ENV}`,
  //   // Specify a version number to identify the deployed version of your application in Datadog
  //   // version: '1.0.0',
  //   sessionSampleRate: 100,
  //   sessionReplaySampleRate: 20,
  //   trackUserInteractions: true,
  //   trackResources: true,
  //   trackLongTasks: true,
  //   defaultPrivacyLevel: "mask-user-input",
  // })
}

export default {
  init,
}
