import formatters from "utils/formatters"

const isUnread = (lastMessage, lastOpened) => {
  const message = new Date(lastMessage)
  const opened = new Date(lastOpened)

  return message > opened
}

export const getLastMessageHour = (date) => {
  if (!date) return null

  const hour = Intl.DateTimeFormat("pt-BR", {
    hour: "numeric",
    minute: "numeric",
    timeZone: "UTC",
  }).format(new Date(date))

  return `${hour}h`
}

export const formatItems = (item) => {
  return {
    ...item,
    phone_number: formatters.toPhone(item?.phone_number),
    hour: getLastMessageHour(item?.last_client_message_at),
    unread: isUnread(item?.last_client_message_at, item?.last_opened_at),
  }
}
