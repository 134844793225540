import { Link } from "gatsby"
import { Mobile } from "components/Devices"
import Icon from "components/Icon"

import "./Mobile.styl"

const Navigation = () => {
  return (
    <Mobile>
      <nav className="navigation-mobile">
        <ul className="navigation-mobile__list">
          <li className="navigation-mobile__item">
            <Link
              className="navigation-mobile__link"
              activeClassName="navigation-mobile__link--highlighted"
              to={ROUTES.home.path}
              aria-label="Início"
            >
              <Icon className="navigation-mobile__icon" name="home" />
            </Link>
          </li>
          <li className="navigation-mobile__item">
            <Link
              className="navigation-mobile__link"
              activeClassName="navigation-mobile__link--highlighted"
              to={ROUTES.products.path}
              aria-label="Produtos"
            >
              <Icon className="navigation-mobile__icon" name="nav" />
            </Link>
          </li>
        </ul>
      </nav>
    </Mobile>
  )
}

export default Navigation
