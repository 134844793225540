import storage from "utils/storage"
import axios from "axios"

const authResolve = (options = {}) => {
  return Promise.resolve()
    .then(getAuthData(options))
    .then(checkExpiration(options))
    .then(setHeaders(options))
    .then(httpInstance(options))
}

const getAuthData =
  ({ key }) =>
  () => {
    const authData = JSON.parse(storage.session.getItem(key))
    return authData
  }

const getAuthorization = (url, headers, key) => {
  return axios
    .get(url, { headers })
    .then(({ data }) => data)
    .then(saveAuthData(key))
    .catch((error) => error)
}

const checkExpiration =
  ({ url, renewUrl, key, headers }) =>
  (authData) => {
    if (!authData) {
      return getAuthorization(url, headers, key)
    } else {
      const now = new Date().getTime()
      const token = { Token: `Bearer ${authData.token}` }

      return authData.expires > now
        ? authData
        : getAuthorization(renewUrl, token, key)
    }
  }

const setHeaders = (options) => (authData) => {
  const headers = {
    Token: `Bearer ${authData.token}`,
    "Content-Type": options?.headers?.contentType,
  }
  return { authData, headers }
}

const httpInstance =
  (options) =>
  ({ authData, headers }) => {
    const http = axios.create({ headers, baseURL: options.baseURL })
    return { authData, headers, http }
  }

const saveAuthData =
  (key) =>
  ({ token, valid_time }) => {
    const expires = (valid_time - 60) * 1000 // to renew 1 minute (60 seconds) before token expiration
    const authData = { token, expires }
    storage.session.setItem(key, JSON.stringify(authData))
    return authData
  }

export default authResolve
