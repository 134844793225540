import { Fragment } from "react"
import If from "components/If"

import useCan from "hooks/useCan"

const Can = ({ children, not = false, and = false, otherwise, ...props }) => {
  const subject = props.subject || props.I || props.business || props.profile
  const action = props.action || props.the || props.do || props.see || props.on

  const url = otherwise?.goTo
  const { allowed: render } = useCan({ subject, action, url, and, not })

  return (
    <If condition={render}>
      <Fragment>{children}</Fragment>
    </If>
  )
}

export default Can
