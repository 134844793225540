export const PROFILES = {
  Master: "master",
  Administrador: "admin",
  "Admin Itaú": "admin-itau",
  Vendedor: "salesman",
}

export const GLOBAL_CODES = {
  P000001: "management-users",
  P000002: "management-companies",
  P000005: "management-businesses",
  // In the API there are two permissions for users, but in the client they have the same behavior.
  P000015: "management-users",
}

export const BUSINESS_CODE = {
  P000003: "sell",
  P000004: "simulation",
  P000006: "upload",
  P000007: "leads-management",
  P000008: "proposals",
  P000009: "download",
  P000010: "redistribution",
  P000011: "admin-management",
  P000012: "wallet-management",
  P000013: "whatsapp-management",
  P000014: "remuneration",
}
