import signin from "../signin"
import { getBusinessList, parseBusinessList } from "./utils"
import formatters from "utils/formatters"

const add = (formData) => {
  const { name, document_number, business_ids } = formData

  return signin()
    .then(({ http }) => {
      return http.post(`/itau-redsales-api/groups/`, {
        name,
        document_number: formatters.removeSpecialChars(document_number),
        business_ids: parseBusinessList(business_ids),
      })
    })
    .then(({ data }) => data)
}

const edit = (formData, group_id) => {
  const { name, document_number } = formData
  return signin()
    .then(({ http }) => {
      return http.put(`/itau-redsales-api/groups/${group_id}`, {
        name,
        document_number: formatters.removeSpecialChars(document_number),
        business_ids: getBusinessList(formData),
      })
    })
    .then(({ data }) => data)
}

const get = ({ group_id }) => {
  return signin()
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/groups/${group_id}`)
    })
    .then(({ data }) => data)
}

export default {
  add,
  edit,
  get,
}
