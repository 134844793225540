import If from "components/If"
import useFeatureToggle from "hooks/useFeatureToggle"

const FeatureToggle = ({ children, enabledEnv }) => {
  const { featureToggleEnabled } = useFeatureToggle({ enabledEnv })

  return <If condition={featureToggleEnabled} renderIf={children} />
}

export default FeatureToggle
