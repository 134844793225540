import Icon from "components/Icon"

import "./Message.styl"

const Message = () => {
  return (
    <div className="message-feedback" data-message>
      <button className="message-feedback__close" data-message-close>
        <Icon name="close" />
      </button>
      <p className="message-feedback__text" data-message-content></p>
    </div>
  )
}

export default Message
