import { Helmet } from "react-helmet"

const SEO = ({ title = "", link = [] }) => {
  return (
    <Helmet
      lang
      title={`PIVO - Banco Itaú | ${title}`}
      link={link}
      meta={[{ name: "robots", content: "noindex, nofollow" }]}
    >
      <html lang="pt-br" />
    </Helmet>
  )
}

export default SEO
