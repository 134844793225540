// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-auth-change-password-index-js": () => import("./../../../src/pages/Auth/ChangePassword/index.js" /* webpackChunkName: "component---src-pages-auth-change-password-index-js" */),
  "component---src-pages-auth-first-access-index-js": () => import("./../../../src/pages/Auth/FirstAccess/index.js" /* webpackChunkName: "component---src-pages-auth-first-access-index-js" */),
  "component---src-pages-auth-forgot-password-index-js": () => import("./../../../src/pages/Auth/ForgotPassword/index.js" /* webpackChunkName: "component---src-pages-auth-forgot-password-index-js" */),
  "component---src-pages-auth-login-index-js": () => import("./../../../src/pages/Auth/Login/index.js" /* webpackChunkName: "component---src-pages-auth-login-index-js" */),
  "component---src-pages-configs-add-company-index-js": () => import("./../../../src/pages/Configs/AddCompany/index.js" /* webpackChunkName: "component---src-pages-configs-add-company-index-js" */),
  "component---src-pages-configs-add-user-index-js": () => import("./../../../src/pages/Configs/AddUser/index.js" /* webpackChunkName: "component---src-pages-configs-add-user-index-js" */),
  "component---src-pages-configs-companies-index-js": () => import("./../../../src/pages/Configs/Companies/index.js" /* webpackChunkName: "component---src-pages-configs-companies-index-js" */),
  "component---src-pages-configs-edit-company-index-js": () => import("./../../../src/pages/Configs/EditCompany/index.js" /* webpackChunkName: "component---src-pages-configs-edit-company-index-js" */),
  "component---src-pages-configs-edit-user-index-js": () => import("./../../../src/pages/Configs/EditUser/index.js" /* webpackChunkName: "component---src-pages-configs-edit-user-index-js" */),
  "component---src-pages-configs-users-index-js": () => import("./../../../src/pages/Configs/Users/index.js" /* webpackChunkName: "component---src-pages-configs-users-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-management-companies-index-js": () => import("./../../../src/pages/Management/Companies/index.js" /* webpackChunkName: "component---src-pages-management-companies-index-js" */),
  "component---src-pages-management-company-index-js": () => import("./../../../src/pages/Management/Company/index.js" /* webpackChunkName: "component---src-pages-management-company-index-js" */),
  "component---src-pages-management-unassigned-leads-index-js": () => import("./../../../src/pages/Management/UnassignedLeads/index.js" /* webpackChunkName: "component---src-pages-management-unassigned-leads-index-js" */),
  "component---src-pages-potential-customers-index-js": () => import("./../../../src/pages/PotentialCustomers/index.js" /* webpackChunkName: "component---src-pages-potential-customers-index-js" */),
  "component---src-pages-products-checking-account-index-js": () => import("./../../../src/pages/Products/CheckingAccount/index.js" /* webpackChunkName: "component---src-pages-products-checking-account-index-js" */),
  "component---src-pages-products-consignment-index-js": () => import("./../../../src/pages/Products/Consignment/index.js" /* webpackChunkName: "component---src-pages-products-consignment-index-js" */),
  "component---src-pages-products-consortium-index-js": () => import("./../../../src/pages/Products/Consortium/index.js" /* webpackChunkName: "component---src-pages-products-consortium-index-js" */),
  "component---src-pages-products-fgts-index-js": () => import("./../../../src/pages/Products/FGTS/index.js" /* webpackChunkName: "component---src-pages-products-fgts-index-js" */),
  "component---src-pages-products-home-index-js": () => import("./../../../src/pages/Products/Home/index.js" /* webpackChunkName: "component---src-pages-products-home-index-js" */),
  "component---src-pages-products-icdc-index-js": () => import("./../../../src/pages/Products/ICDC/index.js" /* webpackChunkName: "component---src-pages-products-icdc-index-js" */),
  "component---src-pages-products-microloans-index-js": () => import("./../../../src/pages/Products/Microloans/index.js" /* webpackChunkName: "component---src-pages-products-microloans-index-js" */),
  "component---src-pages-products-rede-index-js": () => import("./../../../src/pages/Products/Rede/index.js" /* webpackChunkName: "component---src-pages-products-rede-index-js" */),
  "component---src-pages-products-vehicles-index-js": () => import("./../../../src/pages/Products/Vehicles/index.js" /* webpackChunkName: "component---src-pages-products-vehicles-index-js" */),
  "component---src-pages-proposals-index-js": () => import("./../../../src/pages/Proposals/index.js" /* webpackChunkName: "component---src-pages-proposals-index-js" */),
  "component---src-pages-remuneration-details-index-js": () => import("./../../../src/pages/Remuneration/Details/index.js" /* webpackChunkName: "component---src-pages-remuneration-details-index-js" */),
  "component---src-pages-remuneration-main-index-js": () => import("./../../../src/pages/Remuneration/Main/index.js" /* webpackChunkName: "component---src-pages-remuneration-main-index-js" */),
  "component---src-pages-reports-index-js": () => import("./../../../src/pages/Reports/index.js" /* webpackChunkName: "component---src-pages-reports-index-js" */),
  "component---src-pages-upload-index-js": () => import("./../../../src/pages/Upload/index.js" /* webpackChunkName: "component---src-pages-upload-index-js" */),
  "component---src-pages-wallets-wallet-details-index-js": () => import("./../../../src/pages/Wallets/WalletDetails/index.js" /* webpackChunkName: "component---src-pages-wallets-wallet-details-index-js" */),
  "component---src-pages-wallets-wallet-list-components-actives-index-js": () => import("./../../../src/pages/Wallets/WalletList/components/Actives/index.js" /* webpackChunkName: "component---src-pages-wallets-wallet-list-components-actives-index-js" */),
  "component---src-pages-wallets-wallet-list-components-history-index-js": () => import("./../../../src/pages/Wallets/WalletList/components/History/index.js" /* webpackChunkName: "component---src-pages-wallets-wallet-list-components-history-index-js" */),
  "component---src-pages-wallets-wallet-list-components-indicators-index-js": () => import("./../../../src/pages/Wallets/WalletList/components/Indicators/index.js" /* webpackChunkName: "component---src-pages-wallets-wallet-list-components-indicators-index-js" */)
}

