import signin from "../signin"
import { formatFormAnswers } from "./utils"
import formatters from "utils/formatters"
import {
  Lead,
  Leads,
  Kanban,
  Iframes,
  Proposals,
  Redistribution,
  ManagementCompany,
  FormatFilterLeads,
  ManagementCompanies,
} from "./entities"

const upload = ({ business_id, file, file_same_name, type }) => {
  return signin({ hideLoader: true, contentType: "multipart/form-data" })
    .then(({ http }) => {
      const data = new FormData()
      data.append("file", file)
      data.append("business_id", business_id)
      data.append("file_same_name", file_same_name)

      if (type) {
        data.append("template_type", type.trim())
      }

      return http.post("/itau-redsales-api/bases/lead/upload/", data)
    })
    .then(({ data }) => data)
}

const getProposals = ({
  business_id,
  search,
  proposal_date,
  status,
  pagination,
  hideLoader,
}) => {
  return signin({ hideLoader })
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/bases/${business_id}/proposals/`, {
        headers: {
          "x-search-filter": search ? search : "",
        },
        params: {
          ...pagination,
          status: status ? status : null,
          proposal_date: formatters.formatStringDate(proposal_date),
        },
      })
    })
    .then(Proposals)
}

const putProposals = ({ business_id }) => {
  return signin()
    .then(({ http }) => {
      return http.put(`/itau-redsales-api/bases/${business_id}/proposals/`, {})
    })
    .then(Proposals)
}

const cancelProposal = ({ business_id, external_id, reason }) => {
  return signin()
    .then(({ http }) => {
      return http.put(
        `/itau-redsales-api/bases/${business_id}/${external_id}/proposals/cancellation/`,
        {
          reason,
        }
      )
    })
    .then(Proposals)
    .catch((error) => error)
}

const iframe = ({ business_id }) => {
  return signin()
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/bases/${business_id}/iframe/`)
    })
    .then(Iframes)
}

const leads = ({
  business_id,
  without_user,
  hideLoader,
  pagination,
  user_uuid,
  filters,
  search = "",
  group_id,
}) => {
  return signin({ hideLoader })
    .then(({ http }) => {
      const endpoint = user_uuid
        ? `/itau-redsales-api/bases/${business_id}/users/${user_uuid}/leads`
        : `/itau-redsales-api/bases/${business_id}/leads`

      return http.get(endpoint, {
        headers: {
          "x-search-filter": search ? search : "",
        },
        params: {
          ...pagination,
          ...FormatFilterLeads(filters),
          without_user,
          group_id,
        },
      })
    })
    .then(Leads)
}

const lead = ({ business_id, lead_uuid }) => {
  return signin()
    .then(({ http }) => {
      return http.get(
        `/itau-redsales-api/bases/${business_id}/leads/${lead_uuid}/`
      )
    })
    .then(Lead)
}

const update = ({
  business_name,
  lead_uuid,
  lead_status_id,
  observations = "",
}) => {
  return signin()
    .then(({ http }) => {
      return http.put(
        `/itau-redsales-api/bases/${business_name}/leads/${lead_uuid}/`,
        {
          lead_status_id: Number(lead_status_id),
          observations,
        }
      )
    })
    .then(Lead)
    .catch((error) => error)
}

const download = ({ business_id, group_id }) => {
  return signin()
    .then(({ http }) => {
      return http.get(
        `itau-redsales-api/bases/${business_id}/groups/${group_id}/download`
      )
    })
    .then(({ data }) => data)
}

const filters = ({ business_id, hideLoader }) => {
  return signin({ hideLoader })
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/bases/${business_id}/filters/`)
    })
    .then(({ data }) => data)
}

const formQuestions = ({
  business_id,
  hideLoader,
  question_order,
  contact_type = "",
  answer_id = null,
}) => {
  return signin({ hideLoader })
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/bases/${business_id}/form/`, {
        params: {
          answer_id,
          question_order,
          contact_type: String(contact_type).toLocaleLowerCase(),
        },
      })
    })
    .then(({ data }) => data)
}

const formAnswers = ({
  answers,
  formData,
  lead_uuid,
  business_id,
  contact_type = "",
}) => {
  return signin()
    .then(({ http }) => {
      return http.post(`/itau-redsales-api/bases/${business_id}/form/`, {
        lead_uuid,
        answers: formatFormAnswers({ formData, answers }),
        contact_type: String(contact_type).toLocaleLowerCase(),
      })
    })
    .then(({ data }) => data)
}

const history = ({ business_id, lead_uuid }) => {
  return signin()
    .then(({ http }) => {
      return http.get(
        `/itau-redsales-api/bases/${business_id}/leads/${lead_uuid}/history/`
      )
    })
    .then(({ data }) => data)
}

const managementCompanies = ({ business_id, pagination }) => {
  return signin()
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/bases/admin/${business_id}/groups`, {
        params: {
          ...pagination,
        },
      })
    })
    .then(({ data }) => data)
    .then(ManagementCompanies)
}

const managementCompany = ({
  business_id,
  page,
  group_id,
  search,
  searchBy,
}) => {
  const getType = {
    users: () => {
      return signin({ hideLoader: true })
        .then(({ http }) => {
          return http.get(
            `/itau-redsales-api/bases/admin/${business_id}/groups/${group_id}/`,
            {
              params: {
                page,
                search,
              },
            }
          )
        })
        .then(({ data }) => data)
        .then(ManagementCompany)
    },
    clients: () => {
      return signin({ hideLoader: true })
        .then(({ http }) => {
          return http.get(
            `/itau-redsales-api/bases/${business_id}/leads/search/${group_id}/`,
            {
              headers: {
                "x-search-filter": search || "",
              },
              params: {
                page,
                quantity: 9,
              },
            }
          )
        })
        .then(({ data }) => data)
        .then(Kanban)
    },
  }

  return getType[searchBy]()
}

const getRedistribution = ({ business_id, user_uuid, group_id }) => {
  return signin()
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/bases/redistribution/`, {
        params: {
          business_id,
          user_id: user_uuid,
          group_id,
        },
      })
    })
    .then(({ data }) => data)
    .then(Redistribution)
}

const putRedistribution = ({ data, group_id, business_id, user_uuid }) => {
  return signin({ hideLoader: true })
    .then(({ http }) => {
      return http.put(
        `/itau-redsales-api/bases/${business_id}/redistribution/`,
        data,
        {
          params: {
            user_uuid,
            group_id,
          },
        }
      )
    })
    .then(({ data }) => data)
}

export default {
  upload,
  leads,
  lead,
  update,
  proposals: {
    get: getProposals,
    update: putProposals,
    cancel: cancelProposal,
  },
  iframe,
  download,
  filters,
  history,
  management: {
    companies: managementCompanies,
    company: managementCompany,
    redistribution: {
      get: getRedistribution,
      put: putRedistribution,
    },
  },
  form: {
    questions: formQuestions,
    answers: formAnswers,
  },
}
