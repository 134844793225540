import signin from "services/signin"
import { CompanyList, Fee, FeeList } from "./entities"
import { formatPayload } from "./utils"

const getAllCompetitorCompanies = async ({
  business_id,
  hideLoader = false,
}) => {
  return signin({ hideLoader })
    .then(({ http }) =>
      http.get(`/itau-redsales-api/competitor-companies/${business_id}/`)
    )
    .then(CompanyList)
}

const getAllFeeOffers = async ({
  lead_uuid,
  business_id,
  hideLoader = false,
}) => {
  return signin({ hideLoader })
    .then(({ http }) =>
      http.get(
        `/itau-redsales-api/bases/${business_id}/leads/${lead_uuid}/fee-offers`
      )
    )
    .then(FeeList)
}

const createFeeOffer = async ({
  lead_uuid,
  business_id,
  payload = {},
  hideLoader = false,
}) => {
  return signin({ hideLoader })
    .then(({ http }) =>
      http.post(
        `/itau-redsales-api/bases/${business_id}/leads/${lead_uuid}/fee-offers`,
        formatPayload({ payload })
      )
    )
    .then(Fee)
}

const updateFeeOffer = async ({
  lead_uuid,
  business_id,
  fee_offer_id,
  payload = {},
  hideLoader = false,
}) => {
  return signin({ hideLoader })
    .then(({ http }) =>
      http.put(
        `/itau-redsales-api/bases/${business_id}/leads/${lead_uuid}/fee-offers/${fee_offer_id}`,
        formatPayload({ payload })
      )
    )
    .then(Fee)
}

export default {
  company: {
    list: getAllCompetitorCompanies,
  },
  fee: {
    update: updateFeeOffer,
    list: getAllFeeOffers,
    create: createFeeOffer,
  },
}
