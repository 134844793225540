export const WALLET_STATUS_COLOR_STATE = {
  liquidada: "green",
  liquidado: "green",
  atraso: "red",
  atrasada: "red",
  atrasado: "red",
  "em atraso": "red",
  cancelado: "red",
  cancelada: "red",
  ativa: "secondary-lightest",
  ativo: "secondary-lightest",
  aberto: "secondary-lightest",
  aberta: "secondary-lightest",
  "em dia": "secondary-lightest",
}
