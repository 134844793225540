import { Link } from "gatsby"
import Can from "components/Can"
import Icon from "components/Icon"
import _product from "utils/product"
import { SETTINGS } from "./constants"
import { Desktop } from "components/Devices"

import classnames from "classnames"

import useNavigation from "./hooks"

import "./Desktop.styl"

const Navigation = ({ className }) => {
  const { products, onLogout, productIds } = useNavigation()
  return (
    <Desktop>
      <nav
        className={classnames("menu-navigation", {
          [className]: className,
        })}
      >
        <ul className="menu-navigation__list">
          <li className="menu-navigation__item">
            <Link
              className="menu-navigation__link"
              activeClassName="menu-navigation__link--highlighted"
              to={ROUTES.home.path}
            >
              <Icon name="home" />
              <span className="menu-navigation__name">Início</span>
            </Link>
          </li>
          <Can
            business={_product.getAllBusiness({ repeat: 2 })}
            do={_product.fillActions({
              action: ["leads-management", "admin-management"],
            })}
          >
            <li className="menu-navigation__item">
              <Link
                to={ROUTES.reports.path}
                className="menu-navigation__link"
                activeClassName="menu-navigation__link--highlighted"
                partiallyActive={true}
              >
                <Icon name="graph-line" />
                <span className="menu-navigation__name">Relatórios</span>
              </Link>
            </li>
          </Can>
        </ul>
        <ul className="menu-navigation__list">
          <h2 className="menu-navigation__title">Produtos</h2>
          {productIds.map((productId, index) => (
            <Can
              subject={products[productId]?.permission?.subject}
              action={products[productId]?.permission?.action}
              key={`${products[productId]?.path}${index}`}
            >
              <li className="menu-navigation__item">
                <Link
                  className="menu-navigation__link"
                  getProps={({ isCurrent, isPartiallyCurrent }) => {
                    return {
                      className: classnames("menu-navigation__link", {
                        "menu-navigation__link--highlighted": isCurrent,
                        "menu-navigation__link--not-highlighted":
                          isPartiallyCurrent,
                      }),
                    }
                  }}
                  partiallyActive={true}
                  to={products[productId]?.path}
                >
                  <Icon name={products[productId]?.icon} />
                  <span className="menu-navigation__name">
                    {products[productId]?.name}
                  </span>
                </Link>
                <ul className="menu-navigation__children">
                  {Array.isArray(products[productId]?.children) &&
                    products[productId]?.children.map((children) => (
                      <Can
                        subject={children.permission.subject}
                        action={children.permission.action}
                        and={children.permission?.and}
                        key={children.path}
                      >
                        <li className="menu-navigation__children-item">
                          <Link
                            className="menu-navigation__link"
                            activeClassName="menu-navigation__link--highlighted"
                            to={children.path}
                          >
                            {children.name}
                          </Link>
                        </li>
                      </Can>
                    ))}
                </ul>
              </li>
            </Can>
          ))}
        </ul>
        <Can
          business={["management", "management"]}
          the={["users", "companies"]}
        >
          <ul className="menu-navigation__list">
            <h2 className="menu-navigation__title">Gestão de acessos</h2>
            {SETTINGS.map((setting) => (
              <Can
                subject={setting.permission.subject}
                action={setting.permission.action}
                key={setting.name}
              >
                <li className="menu-navigation__item">
                  <Link
                    className="menu-navigation__link"
                    activeClassName="menu-navigation__link--highlighted"
                    to={setting.path}
                  >
                    <Icon name={setting.icon} />
                    <span className="menu-navigation__name">
                      {setting.name}
                    </span>
                  </Link>
                </li>
              </Can>
            ))}
          </ul>
        </Can>
        <span
          className="menu-navigation__logout"
          data-test="logout"
          onClick={onLogout}
        >
          <Icon name="arrow-left" />
          Sair
        </span>
      </nav>
    </Desktop>
  )
}
export default Navigation
