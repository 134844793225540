import isClient from "utils/isClient"
import storage from "utils/storage"
import jwt_decode from "jwt-decode"

const decoding = (payload) => {
  return jwt_decode(payload)
}

const getDataFromToken = (token) => {
  if (isClient() && token) {
    const decodedPayload = decoding(token) || "{}"
    return decodedPayload
  } else {
    return {}
  }
}

const getUser = () => {
  if (isClient()) {
    const appToken = JSON.parse(storage.session.getItem("appToken"))
    const user = getDataFromToken(appToken?.token)

    return user
  }
}

const parseToken = ({ token, valid_time }) => ({
  token,
  expires: (valid_time - 60) * 1000,
})

export default { decoding, getDataFromToken, getUser, parseToken }
