import moment from "moment"
import localization from "moment/locale/pt-br"
import formatters from "utils/formatters"

export const calc = ({ quantity, users, userIndex }) => {
  const remainder = quantity % users // remainder from the division between lead quantity for users
  const division = Math.floor(quantity / users) // rounded division

  const isQuantitySmallerThanUsers = quantity < users
  const isUserSmallerThanRemainder = userIndex < remainder

  if (isQuantitySmallerThanUsers && isUserSmallerThanRemainder) {
    return 1
  }

  if (isUserSmallerThanRemainder) {
    return division + 1
  }

  return division
}

export const getCurrentLeadByStatusId = ({
  navegation_business,
  key,
  object,
}) => {
  return navegation_business
    ?.find((item) => item?.business_id === Number(key))
    .leads_count_by_status?.find((item) => item.status_id === object.status_id)
}

export const isInputDate = (value) => {
  const pattern = new RegExp(/([0-9]{4})-([0-9]{2})-([0-9]{2})/g)
  return pattern.test(value)
}

export const getCurrentValue = (value) => {
  const formDataValue = isInputDate(value)
    ? formatters.formatStringDate(value)
    : value
  return typeof formDataValue === "string" ? formDataValue : ""
}

export const formatFormAnswers = ({ formData, answers }) => {
  const types = {
    written: "written_answer",
    answer: "answer_id",
  }

  const answersWithoutNillValues = answers
    .map((answer) => ({
      question_order: answer.question_order,
      [types.answer]: answer.answer_id ? Number(answer.answer_id) : undefined,
      [types.written]: answer.written_answer,
    }))
    .map((answer) =>
      Object.keys(answer).reduce(
        (acc, key) =>
          answer[key] === undefined
            ? { ...acc }
            : { ...acc, [key]: answer[key] },
        {}
      )
    )

  const lastItem = answersWithoutNillValues.pop()

  return [
    ...answersWithoutNillValues,
    {
      ...lastItem,
      observations: formData.observations,
    },
  ]
}

export const formatToWrittenDate = (date) => {
  const day = moment(date, "DD-MM-YYYY hh:mm:ss")

  moment.locale("pt-br", localization)

  return day.format("[Atualizado] dddd, D [de] MMMM [de] YYYY [às] HH:mm:ss")
}
