import classnames from "classnames"
import Icon from "components/Icon"

import "./Loader.styl"

const Loader = ({ variant = "screen", active = true }) => (
  <div
    className={classnames("loader", {
      [`loader--${variant}`]: variant,
      [`loader--active`]: variant === "component" && active,
    })}
  >
    <div className={"loader__wrapper"}>
      <Icon name="itau" />
      <div className="loader__fast" />
      <div className="loader__spinner" />
    </div>
  </div>
)

export default Loader
