import { Link } from "gatsby"
import { useEffect } from "react"
import Card from "components/Card"
import Icon from "components/Icon"

import storage from "utils/storage"
import isClient from "utils/isClient"

import "./LoggedOut.styl"

const LoggedOut = ({ children }) => {
  useEffect(() => {
    if (isClient()) {
      storage.session.clear()
    }
  }, [])

  return (
    <div className="logged-out">
      <header className="logged-out__header">
        <Link
          className="logged-out__logo"
          to={ROUTES.login.path}
          aria-label="PIVô"
        >
          <Icon name="logo-out" />
        </Link>
      </header>
      <Card className="logged-out__card">{children}</Card>
    </div>
  )
}

export default LoggedOut
