const { BUSINESS_ID_NAME_LIST } = require("constants/products")

const getAllBusiness = ({ repeat = 1, exclude = [] }) => {
  exclude = [exclude].flat()

  return [...Array(repeat).fill([...BUSINESS_ID_NAME_LIST])]
    .flat()
    .filter((business) => !exclude.includes(business))
}

const fillActions = ({
  action = [],
  subtract = 0,
  length = BUSINESS_ID_NAME_LIST.length,
}) => {
  action = [action].flat()

  return action
    .reduce((previousAction, currentAction) => {
      return [
        ...previousAction,
        ...Array(length - subtract).fill(currentAction),
      ]
    }, [])
    .flat()
}

const createPermissionsBySubject = ({ subject, action }) => {
  action = [action].flat()

  return action.map((actionItem) => ({
    action: actionItem,
    subject,
  }))
}

export default {
  fillActions,
  getAllBusiness,
  createPermissionsBySubject,
}
