import signin from "../signin"
import { handleLogin, formatEmail } from "./utils"

const login = ({ login, password }) => {
  return signin()
    .then(({ http }) => {
      return http.post("/itau-redsales-api/auth/login/", {
        login: formatEmail(login),
        password: password,
      })
    })
    .then(handleLogin)
}

const oauth = ({ code }) => {
  return signin({ hideLoader: true })
    .then(({ http }) => {
      const data = new FormData()
      data.append("code", code)

      return http.post("/itau-redsales-api/oauth/token", data)
    })
    .then(handleLogin)
}

const logout = () => {
  return signin().then(({ http }) => {
    return http.post("/itau-redsales-api/auth/logout/", {})
  })
}

const signup = ({ email, password, confirm_password, token }) => {
  return signin()
    .then(({ http }) => {
      return http.post(`/itau-redsales-api/auth/signup/${token}/`, {
        email: formatEmail(email),
        password,
        confirm_password,
      })
    })
    .then(({ data }) => data)
}

const forgotPassword = ({ email }) => {
  return signin()
    .then(({ http }) => {
      return http.post("/itau-redsales-api/auth/forgot_password/", {
        email: formatEmail(email),
      })
    })
    .then(({ data }) => data)
}

const resetPassword = ({ token, email, password, confirm_password }) => {
  return signin()
    .then(({ http }) => {
      return http.post(`/itau-redsales-api/auth/reset_password/${token}/`, {
        email: formatEmail(email),
        password: password,
        confirm_password: confirm_password,
      })
    })
    .then(({ data }) => data)
}

export default {
  signup,
  login,
  oauth,
  forgotPassword,
  resetPassword,
  logout,
}
