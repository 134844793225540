import storage from "utils/storage"

export const formatEmail = (email) => email.trim()

export const handleLogin = ({ data }) => {
  if (data?.token) {
    const expires = (data?.valid_time - 60) * 1000
    storage.session.setItem(
      "appToken",
      JSON.stringify({ token: data?.token, expires })
    )
    return { token: data?.token }
  } else {
    return data
  }
}
