import classnames from "classnames"

const Icon = ({ name, className, ...props }) => {
return (
  <svg className={classnames('icon', {
    [className]: className
  })} {...props}>
    <use xlinkHref={`#${name}`} />
  </svg>
)
}
export default Icon
