import auth from "utils/auth"

const useHeader = () => {
  const { user_name, agent_id, group_name } = auth.getUser()

  return {
    name: user_name,
    group: group_name,
    agentId: agent_id,
  }
}

export default useHeader
