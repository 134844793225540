const getStatus = (active, absent) => {
  if (active) return { text: "Ativo", value: "active" }

  if (absent) return { text: "Ausente", value: "absent" }

  return { text: "Bloqueado", value: "disabled" }
}

const sleep = (ms) => {
  return new Promise((resolve) => {
    const timeout = setTimeout(() => {
      resolve()
      clearTimeout(timeout)
    }, ms)
  })
}

const isSearchable = (str) => (str.length >= 3 ? str : "")

const isValid = (value) => value !== null && value !== undefined

const isNotValid = (value) => !isValid(value)

const wait = (ms) => sleep(ms)

const getItemById = (arr, id) =>
  // eslint-disable-next-line eqeqeq
  arr.find((item) => item.id == id)

export default {
  wait,
  isValid,
  getStatus,
  isNotValid,
  getItemById,
  isSearchable,
}
