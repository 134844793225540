module.exports = [{
      plugin: require('../plugins/gatsby-plugin-font-observer/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-loader-sprite-svg/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/itau-jn6-app-pivo-react-admin-www/itau-jn6-app-pivo-react-admin-www/src/layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PIVO - Banco Itaú","short_name":"PIVO","start_url":"/autenticacao/login","background_color":"#F1F3F8","theme_color":"#ec7000","display":"minimal-ui","icon":"/home/runner/work/itau-jn6-app-pivo-react-admin-www/itau-jn6-app-pivo-react-admin-www/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c8661b6847d78bbcd73daea1dd717487"},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
