import formatters from "utils/formatters"

/** @type {Simulations} */
export const Simulations = ({ data = [] }) =>
  data?.error ? data : data.map(Item)

/** @type {Item} */
export const Item = (item = Object()) => ({
  product_name: item.product_name,
  available_max_value: formatters.toCurrency(item.available_max_value),
  interest_rate_monthly: formatters.toPercent({
    value: item.interest_rate_monthly,
  }),
  interest_rate_yearly: formatters.toPercent({
    value: item.interest_rate_yearly,
  }),
  installments_number: item.installments_number,
  installments_value: formatters.toCurrency(item.installments_value),
  error_message: item?.error_message,
  error_code: item?.error_code,
})
