import { ENV } from "gatsby-env-variables"
import { useState, useEffect } from "react"
import { useParameter } from "hooks/useParameters"

const useFeatureToggle = ({ enabledEnv = "development" }) => {
  enabledEnv = [enabledEnv].flat()
  const [featureToggle] = useParameter("feature_toggle")
  const [featureToggleEnabled, setFeatureToggleEnabled] = useState(false)

  useEffect(() => {
    if (featureToggle || enabledEnv.includes(ENV)) {
      setFeatureToggleEnabled(true)
    } else {
      setFeatureToggleEnabled(false)
    }
  }, [featureToggleEnabled, featureToggle, enabledEnv])

  return {
    featureToggleEnabled,
  }
}

export default useFeatureToggle
