import { formatItems } from "./utils"

/** @type {Leads} */
export const Leads = ({ data: { data } }) => {
  const list = data.map(formatItems)

  return {
    list,
  }
}

/** @type {LeadCounter} */
export const LeadCounter = ({ data }) => data?.count || 0
