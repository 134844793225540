import { Link } from "gatsby"
import Icon from "components/Icon"
import classnames from "classnames"
import useHeader from "./hooks"

import "./Header.styl"
import If from "components/If"

const Header = ({ className }) => {
  const { name, agentId, group } = useHeader()

  return (
    <header
      className={classnames("header", {
        [className]: className,
      })}
    >
      <Link className="header__logo" to={ROUTES.home.path}>
        <Icon name="logo-header" />
      </Link>
      <div className="header__user">
        <p className="header__name">
          {name}
          <br />
          <span className="header__company">{group}</span>
          <If condition={agentId}>
            <span className="header__agent-id">| {agentId}</span>
          </If>
        </p>
        <Icon name="person" />
      </div>
    </header>
  )
}

export default Header
