import classnames from "classnames"
import If from "components/If"

import "./Card.styl"

const Card = ({
  title,
  subtitle,
  className,
  radius = "normal",
  spacing = "normal",
  children,
  ...props
}) => {
  return (
    <div
      className={classnames("card-wrapper", {
        [`card-wrapper--${spacing}`]: spacing,
        [`card-wrapper--radius-${radius}`]: radius,
        [className]: className,
      })}
      {...props}
    >
      <If condition={title || subtitle}>
        <header className="card-wrapper__header">
          <h1 className="card-wrapper__title">{title}</h1>
          <h2 className="card-wrapper__subtitle">{subtitle}</h2>
        </header>
      </If>
      <div className="card-wrapper__content">{children}</div>
    </div>
  )
}

export default Card
