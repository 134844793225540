import { Link } from "gatsby"
import { Desktop } from "components/Devices"
import If from "components/If"

import useBreadcrumb from "./hooks"

import "./Breadcrumb.styl"

const Breadcrumb = ({ crumbLabel, location }) => {
  const { crumbs } = useBreadcrumb({ crumbLabel, location })

  if (!location || location.pathname === "/") {
    return null
  }

  return (
    <nav className="breadcrumb" aria-label="Breadcrumb">
      <ol className="breadcrumb__list">
        {crumbs.map((crumb, index) => (
          <li className="breadcrumb__item" key={index}>
            <Link
              to={crumb.pathname || ""}
              className="breadcrumb__link"
              aria-current={index === crumbs.length - 1 && "page"}
            >
              {crumb.crumbLabel}
            </Link>
            <If
              condition={index === crumbs.length - 1}
              renderElse={
                <span className="breadcrumb__separator" aria-hidden="true">
                  {crumb.crumbSeparator}
                </span>
              }
            />
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Breadcrumb
