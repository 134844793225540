import signin from "../signin"
import services from "services"
import { Tabs, Overview } from "./entities"

const overview = ({ business_id, period_id }) => {
  return signin()
    .then(({ http }) => {
      return http.get(
        `/itau-redsales-api/dashboards/${business_id}/overview/${period_id}`
      )
    })
    .then(Overview)
}

export const tabs = ({ user_id }) => {
  return services.user.permissions({ user_id }, false).then(Tabs)
}

export const periods = () => {
  return signin()
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/dashboards/periods/`)
    })
    .then(({ data }) => data)
}

export default {
  overview,
  periods,
  tabs,
}
