const groupedDataByName = (formData, name) => {
  return Object.keys(formData)
    .filter((key) => key.includes(name))
    .reduce((acc, key) => {
      acc[key] = formData[key]
      return acc
    }, {})
}

export const getBusinessList = (formData) => {
  const business = groupedDataByName(formData, "business")

  const formattedBusinessList = Object.values(business)
    .filter((item) => item)
    .map((item) => parseInt(item))

  return formattedBusinessList
}

const splits = (str) => str?.split("_")

export const putDataBusinessFormatted = (formData) => {
  const business = groupedDataByName(formData, "business")

  const formattedBusinessData = Object.entries(business).reduce(
    (acc, [key, value]) => {
      const group = acc[splits(key)[1]] || []
      group.push({ code: splits(key)[2], block: !value })
      acc[splits(key)[1]] = group
      return acc
    },
    {}
  )

  return formattedBusinessData
}

export const putDataGlobalsFormatted = (formData) => {
  const globals = groupedDataByName(formData, "global")

  const formattedGlobalsData = Object.entries(globals).map(([key, value]) => ({
    code: splits(key)[1],
    block: !value,
  }))

  return formattedGlobalsData
}

export const removeDuplicates = (unfiltered) => {
  const set = new Set()

  for (let permission of unfiltered) {
    set.add(`${permission.subject}-*-${permission.action}`)
  }

  return Array.from(set).map((permission) => {
    const [subject, action] = permission.split("-*-")

    return { subject, action }
  })
}
