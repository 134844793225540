import If from "components/If"

import useMediaQuery from "./hooks"

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery("screen and (max-width: 480px)")
  return <If condition={isMobile} renderIf={children} />
}

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery("screen and (min-width: 768px)")

  return <If condition={isDesktop} renderIf={children} />
}
