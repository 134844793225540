import { useEffect, useCallback, useState } from "react"
import { defaultCrumb, crumbSeparator } from "./constants"
import { getFirstPathCategory, getPathFromUrlWithoutQs } from "./utils"

const useBreadcrumb = ({ location, crumbLabel }) => {
  const [crumbs, setCrumbs] = useState(
    defaultCrumb
      ? [{ pathname: defaultCrumb.location.pathname, ...defaultCrumb }]
      : []
  )

  const updateCrumbs = useCallback(
    ({ location, crumbLabel, crumbSeparator }) => {
      const indexOfCurrentPath = crumbs.findIndex(
        (crumb) => crumb.pathname === location.pathname
      )

      const indexOfDiffPath = crumbs.findIndex(
        (crumb) =>
          getFirstPathCategory(getPathFromUrlWithoutQs(crumb.pathname)) ===
          getFirstPathCategory(getPathFromUrlWithoutQs(location.pathname))
      )

      const hasCurrentPathOnCrumbs =
        indexOfCurrentPath > -1 && indexOfCurrentPath < crumbs.length - 1

      if (location.pathname === "/") {
        return
      }

      if (hasCurrentPathOnCrumbs) {
        setCrumbs(crumbs.slice(0, indexOfCurrentPath))
      }

      if (indexOfCurrentPath === -1) {
        setCrumbs([
          ...crumbs,
          {
            ...location,
            crumbLabel,
            crumbSeparator,
          },
        ])
      }

      if (indexOfDiffPath === -1) {
        setCrumbs([
          { pathname: defaultCrumb.location.pathname, ...defaultCrumb },
          {
            ...location,
            crumbLabel,
            crumbSeparator,
          },
        ])
      }
    },
    [crumbs]
  )

  useEffect(() => {
    updateCrumbs({ location, crumbLabel, crumbSeparator })
  }, [crumbLabel, location, updateCrumbs])

  return {
    crumbs,
  }
}

export default useBreadcrumb
