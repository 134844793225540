import signin from "../signin"
import formatters from "utils/formatters"
import array from "utils/array"
import { putDataBusinessFormatted, putDataGlobalsFormatted } from "./utils"
import { Permissions, Schedule, OrderByScheduleTime } from "./entities"

const add = (formData) => {
  const {
    user_name,
    user_email,
    phone_number,
    profile_id,
    group_id,
    document_number,
    agent_id,
    postal_code,
  } = formData

  return signin()
    .then(({ http }) => {
      return http.post("/itau-redsales-api/users/", {
        user_name,
        user_email,
        document_number: formatters.removeSpecialChars(document_number),
        phone_number: formatters.removeSpecialChars(phone_number),
        profile_id: Number(profile_id),
        group_id: Number(group_id),
        business_ids: [],
        agent_id,
        postal_code: formatters.removeSpecialChars(postal_code),
      })
    })
    .then(({ data }) => data)
}

const edit = ({ formData, userId }) => {
  const {
    user_name,
    user_email,
    phone_number,
    profile_id,
    group_id,
    document_number,
    agent_id,
    postal_code,
  } = formData

  return signin()
    .then(({ http }) => {
      return http.put(`/itau-redsales-api/users/${userId}`, {
        user_name,
        user_email,
        agent_id,
        document_number: formatters.removeSpecialChars(document_number),
        phone_number: formatters.removeSpecialChars(phone_number),
        profile_id: Number(profile_id),
        group_id: Number(group_id),
        postal_code: formatters.removeSpecialChars(postal_code),
        permissions: {
          global_permissions: putDataGlobalsFormatted(formData),
          business_permissions: putDataBusinessFormatted(formData),
        },
      })
    })
    .then(({ data }) => data)
}

const get = ({ user_id }) => {
  return signin()
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/users/${user_id}/`)
    })
    .then(({ data }) => data)
}

const permissions = ({ profile, user_id }, hasEntities = true) => {
  return signin({ hideLoader: true })
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/users/${user_id}/permissions/`)
    })
    .then(({ data }) => (hasEntities ? Permissions({ data, profile }) : data))
}

const companies = (
  { user_id, page = "", quantity = "", group_name = "" },
  { hideLoader = false } = {}
) => {
  return signin({ hideLoader })
    .then(({ http }) => {
      return http.get(
        `/itau-redsales-api/users/${user_id}/groups/?page=${page}&quantity=${quantity}&group_name=${group_name}`
      )
    })
    .then(({ data: { paginated_result } }) => paginated_result)
}

const team = (
  { user_id, group_id, user_name, page },
  { hideLoader = false } = {}
) => {
  return signin({ hideLoader })
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/users/${user_id}/team/`, {
        params: {
          group_id,
          user_name,
          page,
        },
      })
    })
    .then(({ data: { paginated_result } }) => paginated_result)
}

const business = ({ user_id }) => {
  return signin().then(({ http }) => {
    return http
      .get(`/itau-redsales-api/users/${user_id}/business/`)
      .then(({ data }) => data)
  })
}

const profiles = () => {
  return signin()
    .then(({ http }) => {
      return http.get("/itau-redsales-api/profiles/")
    })
    .then(({ data }) => data)
}

const block = ({ user_id }) => {
  return signin()
    .then(({ http }) => {
      return http.put(`/itau-redsales-api/users/${user_id}/block/`, {
        block: true,
      })
    })
    .then(({ data }) => data)
}

const unblock = ({ user_id }) => {
  return signin()
    .then(({ http }) => {
      return http.put(`/itau-redsales-api/users/${user_id}/block/`, {
        block: false,
      })
    })
    .then(({ data }) => data)
}

const absent = ({ user_id, absent, lead_retribution = false }) => {
  return signin()
    .then(({ http }) => {
      return http.put(`/itau-redsales-api/users/${user_id}/absent/`, {
        absent,
        lead_retribution,
      })
    })
    .then(({ data }) => data)
}

const schedule = ({
  business_id,
  user_uuid,
  schedule_at_gte,
  schedule_at_lte,
  is_monthly,
}) => {
  return signin({ hideLoader: true })
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/schedules`, {
        params: {
          business_id: array.toStringList(business_id),
          user_uuid,
          schedule_at_gte,
          schedule_at_lte,
          is_monthly,
        },
      })
    })
    .then(Schedule)
    .then(OrderByScheduleTime)
}

export default {
  permissions,
  companies,
  unblock,
  get,
  block,
  team,
  business,
  add,
  edit,
  profiles,
  absent,
  schedule,
}
