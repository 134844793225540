import _object from "utils/object"
import helpers from "utils/helpers"
import formatters from "utils/formatters"
import { WALLET_STATUS_COLOR_STATE } from "./constants"

/** @type {WalletDetails} */
export const WalletDetails = ({
  data = { client: Object(), contract: Object(), guarantor: Object() },
}) => ({
  client: !!data?.client && {
    ...data?.client,
    document_number: formatters.toCpf(data?.client?.document_number),
  },
  guarantor: !_object.isEmpty(data?.guarantor || {}) && {
    ...data?.guarantor,
    document_number: formatters.toCpf(data?.guarantor?.document_number),
  },
  contract: !!data?.contract && {
    ...data?.contract,
    number: data?.contract?.contract_number,
    type: data?.contract?.contract_type,
    status: data?.contract?.contract_status,
    amount_days_delay: helpers.isValid(data?.contract?.amount_days_delay)
      ? String(data?.contract?.amount_days_delay)
      : "",
    status_color:
      WALLET_STATUS_COLOR_STATE[
        data?.contract?.contract_status?.toLocaleLowerCase()
      ],
    amount:
      data?.contract?.contract_amount >= 0
        ? formatters.toCurrency(data?.contract?.contract_amount)
        : "",
    balance_due:
      data?.contract?.balance_due >= 0
        ? formatters.toCurrency(data?.contract?.balance_due)
        : "",
    nominal_interest_rate: !!data?.contract?.nominal_interest_rate
      ? `${formatters.numberFormatter({
          value: data?.contract.nominal_interest_rate,
        })}%`
      : "",
    contract_date: formatters.isoDateToBrazilianDate(
      data?.contract?.contract_date
    ),
    installments:
      data?.contract?.installments?.map((installment) => ({
        number: installment?.installment_number,
        status: installment?.installment_status,
        status_color:
          WALLET_STATUS_COLOR_STATE[
            installment?.installment_status?.toLocaleLowerCase()
          ],
        due: formatters.isoDateToBrazilianDate(installment?.installment_due),
        value:
          installment?.installment_value >= 0
            ? formatters.toCurrency(installment?.installment_value)
            : "",
      })) || [],
  },
})

/** @type {WalletList} */
export const WalletList = ({ data: response = {} }) =>
  !response.error && {
    paginated_result: { ...response?.pagination },
    wallet_list:
      response?.data?.map(({ client = Object(), contract = Object() }) => ({
        client: {
          ...client,
          full_name: client?.full_name || "",
        },
        contract: {
          ...contract,
          id: contract?.contract_number,
          type: contract?.contract_type,
          status: contract?.contract_status,
          status_color:
            WALLET_STATUS_COLOR_STATE[
              contract?.contract_status?.toLocaleLowerCase()
            ],
          amount:
            contract?.contract_amount >= 0
              ? formatters.toCurrency(contract?.contract_amount)
              : "",
          balance_due:
            contract?.balance_due >= 0
              ? formatters.toCurrency(contract?.balance_due)
              : "",
          amount_days_delay: helpers.isValid(contract?.amount_days_delay)
            ? String(contract?.amount_days_delay)
            : "",
          installments_quantity_contract:
            contract?.installments_quantity_contract || "ND",
          next_installment: !!contract?.next_installment
            ? {
                number: contract?.next_installment?.installment_number || "ND",
                due: formatters.isoDateToBrazilianDate(
                  contract?.next_installment?.installment_due
                ),
                value:
                  contract?.next_installment?.installment_value >= 0
                    ? formatters.toCurrency(
                        contract?.next_installment?.installment_value
                      )
                    : "",
              }
            : {},
        },
      })) || [],
  }

/** @type {WalletIndicators} */
export const WalletIndicators = ({ data }) =>
  data.error
    ? [
        ["total_active_contracts_value", "N/A"],
        ["total_active_contracts_quantity", "N/A"],
        ["total_delayed_contracts_total_value", "N/A"],
        ["total_delayed_contracts_quantity", "N/A"],
        ["total_carency_contracts_total_value", "N/A"],
        ["total_carency_contracts_quantity", "N/A"],
      ]
    : Object.entries({
        total_active_contracts_value: formatters.toCurrency(
          data?.total?.active_contracts?.total_value
        ),
        total_active_contracts_quantity:
          data?.total?.active_contracts?.quantity,

        total_delayed_contracts_total_value: formatters.toCurrency(
          data?.total?.delayed_contracts?.total_value
        ),
        total_delayed_contracts_quantity:
          data?.total?.delayed_contracts?.quantity,

        total_carency_contracts_total_value: formatters.toCurrency(
          data?.total?.carency_contracts?.total_value
        ),
        total_carency_contracts_quantity:
          data?.total?.carency_contracts?.quantity,
      })

/** @type {WalletIndicatorsGeneral} */
export const WalletIndicatorsGeneral = ({ data }) => {
  return {
    total: WalletIndicators({ data }),
    microloans: WalletIndicatorsMicroloans({ data }),
    renegotiation: WalletIndicatorsRenegotiation({ data }),
  }
}

/** @type {MonthlyProductivityGeneral} */
export const MonthlyProductivityGeneral = ({ data }) => {
  return {
    microloans: MonthlyProductivityMicroloans({ data }),
    renegotiation: MonthlyProductivityRenegotiation({ data }),
  }
}

/** @type {WalletIndicatorsMicroloans} */
export const WalletIndicatorsMicroloans = ({ data }) => {
  return data.error ||
    _object.isEmpty(data?.products || data?.products?.microcredito)
    ? [
        ["microloans_active_contracts_total_value", "N/A"],
        ["microloans_active_contracts_quantity", "N/A"],
        ["microloans_delayed_contracts_total_value", "N/A"],
        ["microloans_delayed_contracts_quantity", "N/A"],
        ["microloans_carency_contracts_total_value", "N/A"],
        ["microloans_carency_contracts_quantity", "N/A"],
      ]
    : Object.entries({
        microloans_active_contracts_total_value: formatters.toCurrency(
          data?.products?.microcredito?.active_contracts?.total_value
        ),

        microloans_active_contracts_quantity:
          data?.products?.microcredito?.active_contracts?.quantity,

        microloans_delayed_contracts_total_value: formatters.toCurrency(
          data?.products?.microcredito?.delayed_contracts?.total_value
        ),
        microloans_delayed_contracts_quantity:
          data?.products?.microcredito?.delayed_contracts?.quantity,

        microloans_carency_contracts_total_value: formatters.toCurrency(
          data?.products?.microcredito?.carency_contracts?.total_value
        ),
        microloans_carency_contracts_quantity:
          data?.products?.microcredito?.carency_contracts?.quantity,
      })
}
/** @type {WalletIndicatorsRenegotiation} */
export const WalletIndicatorsRenegotiation = ({ data }) =>
  data.error || _object.isEmpty(data?.products || data?.products?.renegociacao)
    ? [
        ["renegotiation_active_contracts_total_value", "N/A"],
        ["renegotiation_active_contracts_quantity", "N/A"],
        ["renegotiation_delayed_contracts_total_value", "N/A"],
        ["renegotiation_delayed_contracts_quantity", "N/A"],
        ["renegotiation_carency_contracts_total_value", "N/A"],
        ["renegotiation_carency_contracts_quantity", "N/A"],
      ]
    : Object.entries({
        renegotiation_active_contracts_total_value: formatters.toCurrency(
          data?.products?.renegociacao?.active_contracts?.total_value
        ),
        renegotiation_active_contracts_quantity:
          data?.products?.renegociacao?.active_contracts?.quantity,

        renegotiation_delayed_contracts_total_value: formatters.toCurrency(
          data?.products?.renegociacao?.delayed_contracts?.total_value
        ),
        renegotiation_delayed_contracts_quantity:
          data?.products?.renegociacao?.delayed_contracts?.quantity,

        renegotiation_carency_contracts_total_value: formatters.toCurrency(
          data?.products?.renegociacao?.carency_contracts?.total_value
        ),
        renegotiation_carency_contracts_quantity:
          data?.products?.renegociacao?.carency_contracts?.quantity,
      })

/** @type {NonPaymentWallet} */
export const NonPaymentWallet = ({ data }) =>
  data.error
    ? [
        ["total_wallet_balance_on_time", "N/A"],
        ["total_wallet_balance_delay_very_short", "N/A"],
        ["total_wallet_balance_short", "N/A"],
        ["total_wallet_balance_delay_long", "N/A"],
        ["total_non_payment", "N/A"],
        ["non_payment_delay_very_short", "N/A"],
        ["non_payment_delay_short", "N/A"],
        ["non_payment_delay_long", "N/A"],
      ]
    : Object.entries({
        total_wallet_balance_on_time: formatters.toCurrency(
          data?.total_wallet_balance_on_time
        ),
        total_wallet_balance_delay_very_short: formatters.toCurrency(
          data?.total_wallet_balance_delay_very_short
        ),
        total_wallet_balance_short: formatters.toCurrency(
          data?.total_wallet_balance_short
        ),
        total_wallet_balance_delay_long: formatters.toCurrency(
          data?.total_wallet_balance_delay_long
        ),
        total_non_payment: formatters.toPercent({
          value: data?.total_non_payment,
        }),
        non_payment_delay_very_short: formatters.toPercent({
          value: data?.non_payment_delay_very_short,
        }),
        non_payment_delay_short: formatters.toPercent({
          value: data?.non_payment_delay_short,
        }),
        non_payment_delay_long: formatters.toPercent({
          value: data?.non_payment_delay_long,
        }),
      })

/** @type {MonthlyProductivity} */
export const MonthlyProductivity = ({ data }) =>
  data.error
    ? [
        ["amount_delivered_month", "N/A"],
        ["contracting_month_count", "N/A"],
      ]
    : Object.entries({
        amount_delivered_month: formatters.toCurrency(
          data?.amount_delivered_month
        ),
        contracting_month_count: data?.contracting_month_count,
      })

/**   @type {MonthlyProductivityRenegotiation} */
export const MonthlyProductivityRenegotiation = ({ data }) =>
  data.error
    ? [
        ["renegotiation_amount_financed_month", "N/A"],
        [" renegotiation_amount_repaid_month", "N/A"],
        [" renegotiation_contracting_month_count", "N/A"],
      ]
    : Object.entries({
        renegotiation_amount_financed_month: formatters.toCurrency(
          data?.products?.renegociacao?.amount_financed_month
        ),
        renegotiation_contracting_month_count:
          data?.products?.renegociacao?.contracting_month_count,
        renegotiation_amount_repaid_month: formatters.toCurrency(
          data?.products?.renegociacao?.amount_repaid_month
        ),
      })

/**  @type {MonthlyProductivityMicroloans} */
export const MonthlyProductivityMicroloans = ({ data }) =>
  data.error
    ? [
        ["microloans_amount_financed_month", "N/A"],
        [" microloans_amount_repaid_month", "N/A"],
        ["microloans_contracting_month_count", "N/A"],
      ]
    : Object.entries({
        microloans_amount_financed_month: formatters.toCurrency(
          data?.products?.microcredito?.amount_financed_month
        ),
        microloans_contracting_month_count:
          data?.products?.microcredito?.contracting_month_count,
        microloans_amount_repaid_month: formatters.toCurrency(
          data?.products?.microcredito?.amount_repaid_month
        ),
      })

/** @type {RemunerationDescriptions} */
export const RemunerationDescriptions = ({ data }) => data.descriptive_rule

/** @type {RemunerationValues} */
export const RemunerationValues = ({ data }) => ({
  total: data?.specialist_remuneration?.final_remuneration_value || "ND",
  values: {
    period: [
      {
        label: "Data do início do cálculo",
        value: data?.simulation_period?.simulation_start_date,
      },
      {
        label: "Data do fechamento",
        value: data?.simulation_period?.simulation_end_date,
      },
    ],
    origination: [
      {
        label: "Valor mensal liberado",
        value: data?.specialist_remuneration?.production,
      },
    ],
    wallet: [
      {
        label: "Carteira em dia",
        value: data?.specialist_wallet?.value_wallet_on_day,
        hint: "Na remuneração, consideramos como carteira em dia o saldo total da carteira em dia somado ao saldo total da carteira em atraso curtíssimo na data do fechamento",
      },
      {
        label: "Inadimplência de atraso curto",
        value: data?.specialist_wallet?.non_payment_value_short,
      },
      {
        label: "Inadimplência de atraso longo",
        value: data?.specialist_wallet?.non_payment_value_long,
      },
    ],
    wallet_daily_remuneration: [
      {
        label: "Saldo da carteira em dia",
        value: data?.specialist_wallet?.value_wallet_on_day,
      },
      {
        label: "Remuneração da carteira (sem redutor)",
        value: data?.specialist_remuneration?.remuneration_before_reducing,
        difference: "plus",
      },
    ],
    non_payment_short: [
      {
        label: "Saldo da carteira em atraso curto",
        value: data?.specialist_wallet?.non_payment_value_short,
      },
      {
        label: "% inad. de atraso curto",
        value: data?.specialist_wallet?.percentage_non_payment_short,
      },
      {
        label: "Redutor por inad. atraso curto",
        value:
          data?.specialist_wallet?.wallet_reducers
            ?.percentage_reducer_non_payment_short,
        difference: "minus",
      },
      {
        label: "Valor reduzido por atraso curto",
        value:
          data?.specialist_wallet?.wallet_reducers
            ?.reducer_value_non_payment_short,
        difference: "minus",
      },
    ],
    non_payment_long: [
      {
        label: "Saldo da carteira em atraso longo",
        value: data?.specialist_wallet?.non_payment_value_long,
      },
      {
        label: "% inad. de atraso longo",
        value: data?.specialist_wallet?.percentage_non_payment_long,
      },
      {
        label: "Redutor por inad. atraso longo",
        value:
          data?.specialist_wallet?.wallet_reducers
            ?.percentage_reducer_non_payment_long,
        difference: "minus",
      },
      {
        label: "Valor reduzido por atraso longo",
        value:
          data?.specialist_wallet?.wallet_reducers
            ?.reducer_value_non_payment_long,
        difference: "minus",
      },
    ],
    rate: [
      {
        label: "Remuneração por carteira em dia",
        value: data?.specialist_remuneration?.remuneration_before_reducing,
        difference: "plus",
      },
      {
        label: "Valor reduzido por atraso curto",
        value:
          data?.specialist_wallet?.wallet_reducers
            ?.reducer_value_non_payment_short,
        difference: "minus",
      },
      {
        label: "Valor reduzido por atraso longo",
        value:
          data?.specialist_wallet?.wallet_reducers
            ?.reducer_value_non_payment_long,
        difference: "minus",
      },
      {
        label: "Remuneração final por carteira em dia",
        value:
          data?.specialist_remuneration
            ?.remuneration_wallet_on_day_after_reducing,
        difference: "plus",
      },
    ],
    total_remuneration: [
      {
        label: "Originação",
        value: data?.specialist_remuneration?.origination_remuneration,
      },
      {
        label: "Carteira em dia",
        value:
          data?.specialist_remuneration
            ?.remuneration_wallet_on_day_after_reducing,
      },
      {
        label: "Campanha",
        value: data?.specialist_remuneration?.campaign?.campaign_value,
      },
      {
        label: "Total",
        value: data?.specialist_remuneration?.final_remuneration_value,
        difference: "plus",
      },
    ],
    remuneration_by_origination: [
      {
        label: "Valor liberado (mês)",
        value: data?.specialist_remuneration?.production,
      },
      {
        label: "Originação",
        value: data?.specialist_remuneration?.origination_remuneration,
        difference: "plus",
      },
    ],
    remuneration_by_campaign: [
      {
        label: "Campanha",
        value: data?.specialist_remuneration?.campaign?.campaign_value,
        difference: "plus",
      },
    ],
  },
})
