import signin from "../signin"
import formatters from "utils/formatters"

const indication = ({ document_number }) => {
  return signin().then(({ http }) => {
    return http.post(`/itau-redsales-api/suggestions/`, {
      document_number: formatters.removeSpecialChars(document_number),
    })
  })
}

export default {
  indication,
}
