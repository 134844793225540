import signin from "../signin"
import { LeadCounter, Leads } from "./entities"

const leads = ({ business_id }) => {
  return signin({ hideLoader: true }).then(({ http }) => {
    return http
      .get(`/itau-redsales-api/whatsapp/${business_id}/leads`)
      .then(Leads)
  })
}

const leadsCounter = ({ business_id, group_id }) => {
  return signin({ hideLoader: true }).then(({ http }) => {
    return http
      .get(`/itau-redsales-api/whatsapp/${business_id}/${group_id}/count_queue`)
      .then(LeadCounter)
  })
}

const getLeadFromQueue = ({ business_id, group_id }) => {
  return signin({ hideLoader: true }).then(({ http }) => {
    return http
      .put(
        `/itau-redsales-api/whatsapp/${business_id}/${group_id}/take_lead_queue`
      )
      .then(({ data }) => data)
  })
}

const notify = ({ business_id, ticked_uuid }) => {
  return signin({ hideLoader: true }).then(({ http }) => {
    return http
      .put(`/itau-redsales-api/whatsapp/${business_id}/${ticked_uuid}/iframe`)
      .then((data) => data)
  })
}

export default {
  notify,
  leads,
  leadsCounter,
  getLeadFromQueue,
}
