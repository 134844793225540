import { navigate } from "gatsby"
import { useEffect, useCallback, useState } from "react"
import storage from "utils/storage"

const useCan = ({ and, subject, action, url, not: cannot }) => {
  const permissions = JSON.parse(storage.session.getItem("permissions"))
  const [allowed, setAllowed] = useState(null)

  const props = {
    subject: [subject].flat(),
    action: [action].flat(),
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toFiltredRulesByProps = (permission) => {
    for (let i = 0, len = props.subject.length; i < len; i++) {
      if (
        permission.subject === props.subject[i] &&
        permission.action === props.action[i]
      ) {
        return true
      }
    }
    return false
  }

  const handlePermissions = useCallback(() => {
    const filtredPermissions = permissions.filter(toFiltredRulesByProps)

    const sameLengthArray = filtredPermissions.length === props.subject.length
    const allConditionsIsTruly = and && sameLengthArray && !cannot
    const justAnConditionsIsTruly = !and && filtredPermissions.length && !cannot

    if (allConditionsIsTruly) {
      return setAllowed(true)
    }

    if (justAnConditionsIsTruly) {
      return setAllowed(true)
    }

    if (url) {
      return navigate(url)
    }
  }, [
    permissions,
    toFiltredRulesByProps,
    props.subject.length,
    and,
    cannot,
    url,
  ])

  useEffect(() => {
    handlePermissions()
  }, [handlePermissions])

  return {
    allowed,
  }
}

export default useCan
