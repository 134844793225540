/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import isClient from "utils/isClient"

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    if (isClient()) {
      const onResize = () => setMatches(window.matchMedia(query).matches)
      setMatches(window.matchMedia(query).matches)
      window.addEventListener("resize", onResize)
      return () => {
        window.removeEventListener("resize", onResize)
      }
    }
  }, [])

  return matches
}

export default useMediaQuery
