export const SETTINGS = [
  {
    name: "Empresas",
    path: ROUTES.companies.path,
    icon: "company",
    permission: {
      subject: "management",
      action: "companies",
    },
  },
  {
    name: "Usuários",
    path: ROUTES.users.path,
    icon: "user",
    permission: {
      subject: "management",
      action: "users",
    },
  },
]
