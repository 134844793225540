const capitalize = (text) => {
  if (!(typeof text === "string") || text.length < 1) return undefined

  return text[0].toUpperCase() + text.slice(1)
}

const concatenate = (firstText, secondText, char = ", ") =>
  !!firstText || !!secondText ? [firstText, secondText].join(char) : null

export default {
  capitalize,
  concatenate,
}
