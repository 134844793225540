import signin from "../signin"
import {
  WalletList,
  WalletDetails,
  NonPaymentWallet,
  RemunerationValues,
  WalletIndicatorsGeneral,
  RemunerationDescriptions,
  MonthlyProductivityGeneral,
} from "./entities"

const getWalletList = async ({ pagination, hideLoader, filters = {} }) => {
  return signin()
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/microcredito/contracts/`, {
        params: {
          ...filters,
          ...pagination,
        },
      })
    })
    .then(WalletList)
}

const getWalletDetails = async ({ contract_id }) => {
  return signin()
    .then(({ http }) => {
      return http.get(
        `/itau-redsales-api/microcredito/contracts/${contract_id}/`
      )
    })
    .then(WalletDetails)
}
const walletIndicators = async () => {
  return signin()
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/microcredito/contracts/indicators`)
    })
    .then(WalletIndicatorsGeneral)
}

const nonPaymentWallet = async () => {
  return signin()
    .then(({ http }) => {
      return http.get(
        `/itau-redsales-api/microcredito/contracts/indicators/non_payment_wallet`
      )
    })
    .then(NonPaymentWallet)
}

const monthlyProductivity = async () => {
  return signin()
    .then(({ http }) => {
      return http.get(
        `/itau-redsales-api/microcredito/contracts/indicators/monthly_productivity`
      )
    })
    .then(MonthlyProductivityGeneral)
}

const remunerationDescriptions = () => {
  return signin()
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/comissionamento/descriptive/`)
    })
    .then(RemunerationDescriptions)
}

const remunerationValues = () => {
  return signin()
    .then(({ http }) => {
      return http.get(`/itau-redsales-api/comissionamento/prior_closures/`)
    })
    .then(RemunerationValues)
}

export default {
  wallet: {
    getList: getWalletList,
    getDetails: getWalletDetails,
  },
  indicators: {
    wallet: walletIndicators,
    monthlyProductivity,
    nonPaymentWallet,
  },
  remuneration: {
    descriptions: remunerationDescriptions,
    values: remunerationValues,
  },
}
