import helpers from "./helpers"

const toNumber = Number

const removeSpecialChars = (str = "", except = "", removeToo = "") => {
  return str.replace(new RegExp(`[^\\w\\s${except}]|[${removeToo}]`, "gi"), "")
}

const numberFormatter = ({
  style = "decimal",
  value = 0,
  currency = "BRL",
  decimals = 2,
  currencyDisplay = "symbol",
}) => {
  return Intl.NumberFormat("pt-BR", {
    style,
    currency,
    currencyDisplay,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value)
}

const isoDateToBrazilianDate = (date) => {
  return new Date(date)
    .toLocaleDateString("pt-BR", { timeZone: "UTC" })
    .replace("Invalid Date", "")
}

const toCurrency = (value, options = {}) => {
  return typeof value === "number"
    ? numberFormatter({ value, style: "currency", ...options })
    : value
}

const toPercent = ({ value, ...props }) => {
  if (props?.nullableReturn && helpers.isNotValid(value)) return null

  return numberFormatter({
    ...props,
    value: (value || 0) / 100,
    style: "percent",
  })
}

const toPhone = (value, options = {}) => {
  if (!value) return !!options?.nullableReturn ? null : "(00) 0000-0000"

  const isCellphone = value?.length === 11
  return isCellphone
    ? value?.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")
    : value?.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")
}

const toCpf = (value) =>
  value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") ||
  "000.000.000-00"

const toCnpj = (value) =>
  value?.replace(
    /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
    "$1.$2.$3/$4-$5"
  ) || "00.000.000/0000-00"

const toDocument = (value) => {
  if (!value) return "000.000.000-00"

  const document = removeSpecialChars(value)
  const isCnpj = document.length >= 14

  return isCnpj ? toCnpj(value) : toCpf(value)
}

const toPostalCode = (value) =>
  value?.replace(/(\d{5})(\d{3})/, "$1-$2") || "00-00000"

const toPortugueseBoolean = (value, variation = "confirmation") => {
  if ([null, undefined].includes(value)) return null

  if (variation === "confirmation") return !!value ? "Sim" : "Não"

  if (variation === "validation") return !!value ? "Verdadeiro" : "Falso"

  return !!value
}

const formatStringDate = (date) =>
  date ? date?.split("-").reverse().join("-") : null

export default {
  toNumber,
  removeSpecialChars,
  numberFormatter,
  isoDateToBrazilianDate,
  toPortugueseBoolean,
  toCurrency,
  toPercent,
  toPhone,
  toCpf,
  toCnpj,
  toDocument,
  toPostalCode,
  formatStringDate,
}
