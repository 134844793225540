import { useCallback, useEffect, useState } from "react"
import { navigate } from "gatsby"
import services from "services"
import auth from "utils/auth"
import { getProducts } from "./utils"

const useNavigation = () => {
  const user = auth.getUser()
  const products = getProducts({ group_id: user?.group_id })
  const [productIds, setProductIds] = useState([])

  const onLogout = () => {
    services.auth.logout().then(handleLogout)
  }

  const handleLogout = () => {
    navigate(ROUTES.login.path)
  }

  const sortBusiness = useCallback((listBusiness) => {
    const productIdsSorted = listBusiness.map(
      (business) => business.business_id
    )

    setProductIds(productIdsSorted)
  }, [])

  const fetchBusiness = useCallback(async () => {
    return services.user.business({ user_id: user.user_id })
  }, [user.user_id])

  useEffect(() => {
    fetchBusiness().then(sortBusiness)
  }, [fetchBusiness, sortBusiness])

  return {
    products,
    onLogout,
    productIds,
  }
}

export default useNavigation
