import { useEffect } from "react"
import { navigate } from "gatsby"

const Redirect = ({ to }) => {
  useEffect(() => {
    if (to) {
      navigate(to)
    }
  }, [to])

  return null
}

export default Redirect
