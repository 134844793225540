import auth from "./auth"
import user from "./user"
import group from "./group"
import simulations from "./simulations"
import suggestion from "./suggestion"
import feeOfeer from "./feeOffer"
import bases from "./bases"
import reports from "./reports"
import whatsapp from "./whatsapp"
import microloans from "./microloans"

export default {
  auth,
  user,
  suggestion,
  bases,
  group,
  feeOfeer,
  simulations,
  reports,
  whatsapp,
  microloans,
}
