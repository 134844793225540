import { useEffect } from "react"
import auth from "utils/auth"
import storage from "utils/storage"

const useLoggedIn = () => {
  const user = auth.getUser()
  const exp = user?.exp
  const logged_in = user?.logged_in
  const isValidToken = exp * 1000 > Date.now()
  const isLoggedIn = isValidToken && logged_in
  const permissions = JSON.parse(storage.session.getItem("permissions"))

  useEffect(() => {
    console.group("PERMISSIONS", permissions)
    console.groupEnd()
  }, [permissions])

  return {
    isLoggedIn,
  }
}

export default useLoggedIn
