import isClient from "utils/isClient"

const get = () => isClient() && document.querySelector(".loader")
const hide = () =>
  isClient() &&
  setTimeout(() => get()?.classList.remove("loader--active"), 1000)
const show = () => isClient() && get()?.classList.add("loader--active")

export default {
  hide,
  show,
  get,
}
