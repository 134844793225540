import { BUSINESS_ID_NAME } from "constants/products"
import { PROFILES, GLOBAL_CODES, BUSINESS_CODE } from "./constants"
import { removeDuplicates } from "./utils"

export const Permissions = ({ data = Object(), profile = String() }) => {
  const toEntries = (arr) => Object.entries(arr)
  const toKeys = (arr) => Object.keys(arr)
  const isArray = (arr) => Array.isArray(arr)

  const globals = isArray(data.global_permissions)
    ? data.global_permissions.map(Global)
    : []
  const businesses = data.business_permissions
    ? toEntries(data.business_permissions).reduce(Businesses, [])
    : []
  const management = data.business_permissions
    ? toKeys(data.business_permissions).map(BusinessManagement)
    : []
  const unfiltered = [
    ...globals,
    ...businesses,
    ...management,
    {
      subject: PROFILES[profile],
      action: "it",
    },
  ]
  const permissions = removeDuplicates(unfiltered)

  return permissions
}

/** @type {BusinessManagement} */
export const BusinessManagement = (key = Object()) => ({
  subject: "management",
  action: BUSINESS_ID_NAME[key],
})

/** @type {Global} */
export const Global = (permission = Object()) => {
  const [subject, action] = GLOBAL_CODES[permission.code].split("-")
  return { subject, action }
}

/** @type {Businesses} */
export const Businesses = (acc, [key, { permissions }]) => {
  for (const permission of permissions) {
    acc.push({
      subject: BUSINESS_ID_NAME[key],
      action: BUSINESS_CODE[permission.code],
    })
  }
  return acc
}

/** @type {Schedule} */
export const Schedule = ({ data }) => data

/** @type {OrderByScheduleTime} */
export const OrderByScheduleTime = (data = []) =>
  data.sort((a, b) => a.schedule_time.localeCompare(b.schedule_time))
